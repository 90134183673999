import React, { useState } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import EditModal from '../EditModal';
import { S3Image } from 'aws-amplify-react';
import './marker.css';

export default function CustomMarker(props) {
	const [showInfo, setShowInfo] = useState(false);
	const [open, setOpen] = useState(false);
	const { marker } = props;
	const { recordingDate, use, pricePerAcre, surfaceWater, comp } = marker;
	const handleShowInfo = () => {
		setShowInfo(true);
	};
	const handleHideInfo = () => {
		setShowInfo(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const ppa = (Number(pricePerAcre) || 0).toLocaleString(undefined, {
		maximumFractionDigits: 0,
	});
	const imgKey = (((comp.MainImage || {}).values || [])[0] || {}).s3Key || '';
	return (
		<Marker
			onClick={handleShowInfo}
			key={`markerKey-${props.index}`}
			position={{
				lat: Number(marker.latitude),
				lng: Number(marker.longitude),
			}}
		>
			{showInfo && (
				<InfoWindow onCloseClick={handleHideInfo}>
					<div className="infoText">
						<h4>
							Date of Sale:{' '}
							<span className="markerInfoValue">{recordingDate || 'N/A'}</span>
						</h4>
						{use && (
							<h4>
								Plantings: <span className="markerInfoValue">{use}</span>
							</h4>
						)}
						{pricePerAcre && (
							<h4>
								$/Acre: <span className="markerInfoValue">${ppa}</span>
							</h4>
						)}
						{use && (
							<h4>
								Water District:{' '}
								<span className="markerInfoValue">{surfaceWater}</span>
							</h4>
						)}
						<div className="imageContainer">
							{!imgKey && (
								<div className="noImage" onClick={handleOpen}>
									No Image
								</div>
							)}
							{imgKey && <S3Image imgKey={imgKey} onClick={handleOpen} />}
						</div>
						<EditModal comp={comp} handleClose={handleClose} open={open} />
					</div>
				</InfoWindow>
			)}
		</Marker>
	);
}
