/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:ee604dbe-cac6-489f-bbd7-5ba3fd724499",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_WFEIcXTVG",
    "aws_user_pools_web_client_id": "2ekthkfpk0pn93k4unojp2jta3",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "users-prod",
            "region": "us-west-2"
        },
        {
            "tableName": "records-prod",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "simonhower-record-attachments50704-prod",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "howerAPI",
            "endpoint": "https://hdq2tfhmwd.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "reportsAPI",
            "endpoint": "https://c50gevb680.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        },
        {
            "name": "excelAPI",
            "endpoint": "https://hm1fptz7yj.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
