import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import TextInput from './TextInput';

const MoneyInput = (props) => {
	const { isneg } = props;
	let inputProps = {
		...props.InputProps,
		startAdornment: <InputAdornment position="start">$</InputAdornment>,
	};
	if (isneg === 'true' || Number(props.value) < 0) {
		inputProps = {
			...inputProps,
			startAdornment: <InputAdornment position="start">$ (</InputAdornment>,
			endAdornment: <InputAdornment position="start">)</InputAdornment>,
		};
	}
	return <TextInput {...props} type="number" InputProps={inputProps} />;
};

export default MoneyInput;
