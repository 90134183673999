import CardContent from '@material-ui/core/CardContent';
import Context from './Context';
import Field from './Field';
import React from 'react';
import RemoveCustomField from './RemoveCustomField';
import Wrapper from './Wrapper';
import { makeStyles } from '@material-ui/core/styles';
const { irr } = require('node-irr');

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: theme.spacing(-1),
	},
	full: {
		paddingBottom: theme.spacing(0.25),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.25),
		width: '100%',
	},
	half: {
		paddingBottom: theme.spacing(0.25),
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.25),
		width: '50%',
	},
	input: {
		background: '#fff',
	},
	customValue: {
		color: '#5e5e5e',
		fontSize: '14px',
		width: 'calc(50% - 16px)',
		border: '1px solid #c4c4c4',
		borderRadius: '5px',
		padding: '3px 8px',
		margin: '10px 8px',
		height: '40px',
		display: 'flex',
		alignItems: 'center',
	},
}));
const TYPES = {
	money: '$',
	percent: '%',
};
const nwc = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const Basic = ({ isLease, values = {}, formState }) => {
	const { actions, fields } = React.useContext(Context);
	const classes = useStyles();
	const getVal = (pk, k) => {
		const vals = (((formState || {}).data || {})[pk] || {}).values || [];
		return k ? vals[k] : vals;
	};
	return (
		<Wrapper>
			<CardContent className={`${classes.root} BasicCard-Content`}>
				{fields.map(
					(
						{
							hideOnLease,
							isCustom,
							key,
							label,
							type,
							calc,
							isIRR,
							copyValue,
							isSubtracted,
						},
						index
					) => {
						const isneg = (
							isSubtracted !== undefined && isSubtracted
						).toString();
						// handle calculated values
						console.log(`formState ===>>>`, formState);
						if (isIRR && (formState || {}).data) {
							const irrVals = (formState.data.PlantingsIRR || {}).values;
							if (irrVals) {
								const allIRRKeys = Object.keys(irrVals).filter((v) =>
									v.includes('CF')
								);
								const sortedKeys = allIRRKeys.sort();
								const irrData = sortedKeys.map((key) => Number(irrVals[key]));
								const irrResult = irr(irrData);
								const resultToFixed = irrResult.toFixed(4);
								const resPercent = Number(
									(Number(resultToFixed) * 100).toFixed(2)
								);
								if (irrResult && !isNaN(irrResult)) {
									return (
										<div key={`irr`} className={classes.customValue}>
											{label}: {TYPES[type]}
											{nwc(resPercent)}
										</div>
									);
								}
							}
							return '';
						}
						if (calc !== undefined && typeof calc === 'object') {
							const { func, values } = calc;
							if (typeof func === 'function' && typeof values === 'object') {
								// get values from the form (if they have been filled out yet)
								let valExists = true;
								const vals = values.map((v) => {
									const { parentKey, key, dep } = v;
									let valFound = getVal(parentKey, key);
									if (
										valFound === undefined ||
										(Array.isArray(valFound) && valFound.length < 1 && dep)
									) {
										valExists = false;
									}
									return valFound;
								});
								const calculatedValue = func(...vals);
								if (
									valExists &&
									calculatedValue !== undefined &&
									!isNaN(calculatedValue)
								) {
									const numVal = Number(calculatedValue);
									const roundedVal = Number(numVal.toFixed(2));
									const isNeg = numVal < 0;
									const cVal = isNeg ? `(${roundedVal * -1})` : roundedVal;
									return (
										<div
											key={`calculatedValue-${index}`}
											className={classes.customValue}
										>
											{label}: {TYPES[type]}
											{nwc(cVal)}
										</div>
									);
								}
							}
							return '';
						}
						// use copied value
						if (copyValue) {
							const { parentKey, childKey, type } = copyValue;
							if (parentKey && childKey && type) {
								const valueToCopy = getVal(parentKey, childKey);
								if (valueToCopy) {
									const formattedVal =
										type === 'money'
											? `$${nwc(valueToCopy)}`
											: nwc(valueToCopy);
									return (
										<div
											key={`customValue-${key}`}
											className={classes.customValue}
										>
											{label}: {formattedVal}
										</div>
									);
								}
							}
							return '';
						}
						if (isLease) {
							return (
								!hideOnLease && (
									<div
										className={
											fields.length % 2 && index === fields.length - 1
												? classes.full
												: classes.half
										}
										key={key}
									>
										<Field
											isneg={isneg}
											addon={isCustom && <RemoveCustomField fieldKey={key} />}
											fieldKey={key}
											label={label}
											setValue={actions.setValue}
											type={type}
											value={values[key]}
										/>
									</div>
								)
							);
						} else {
							return (
								<div
									className={
										fields.length % 2 && index === fields.length - 1
											? classes.full
											: classes.half
									}
									key={key}
								>
									<Field
										isneg={isneg}
										addon={isCustom && <RemoveCustomField fieldKey={key} />}
										fieldKey={key}
										label={label}
										setValue={actions.setValue}
										type={type}
										value={values[key]}
									/>
								</div>
							);
						}
					}
				)}
			</CardContent>
		</Wrapper>
	);
};

export default React.memo(Basic);
