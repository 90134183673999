import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { recordTypes } from '@hower/shared';
import { useAsyncCallback } from 'async-react-hooks';
import { withRecordForm } from '../RecordForm';
import './FormModal.css';
import ToastServive from 'react-material-toast';

const toast = ToastServive.new({
	place: 'topRight',
	duration: 2,
	maxCount: 4,
});

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const leaseSubCats = [
	'medical',
	'shopping',
	'retail',
	'industrial',
	'office',
	'restaurant',
	'all',
];

const AntSwitch = withStyles((theme) =>
	createStyles({
		root: {
			width: 28,
			height: 16,
			padding: 0,
			display: 'flex',
		},
		switchBase: {
			padding: 2,
			color: theme.palette.grey[500],
			'&$checked': {
				transform: 'translateX(12px)',
				color: theme.palette.common.white,
				'& + $track': {
					opacity: 1,
					backgroundColor: theme.palette.grey[500],
					borderColor: theme.palette.grey[500],
				},
			},
		},
		thumb: {
			width: 12,
			height: 12,
			boxShadow: 'none',
		},
		track: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: theme.palette.common.white,
		},
		checked: {},
	})
)(Switch);

const FormModal = ({
	Form,
	category,
	handleClose,
	handleSave,
	handleSaveNow,
	open,
	subcategory,
}) => {
	const classes = useStyles();
	const [saveCallback, { loading }] = useAsyncCallback(async () => {
		try {
			await handleSave();
			handleClose();
		} catch (error) {
			console.log(`error on save:`, error);
		}
	}, [handleClose, handleSave]);

	const [saveNowCallback, { loadingNow }] = useAsyncCallback(async () => {
		try {
			await handleSaveNow();
			toast.success('Changes Saved');
		} catch (error) {
			toast.error('Saved Failed. Try again.');
			console.log(`error in saveNowCallback:`, error);
		}
	}, [handleSaveNow]);

	const [isLease, setIsLease] = useState(false);
	const toggleCompType = () => {
		const newState = !isLease;
		setIsLease(newState);
	};
	const showLeaseSwitch =
		category.toLowerCase() === 'commercial' &&
		leaseSubCats.includes(subcategory.toLowerCase());

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="lg"
			onClose={handleClose}
			open={open}
		>
			<AppBar>
				<Backdrop open={loading}>
					<CircularProgress />
				</Backdrop>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Add Data
						<ChevronRightIcon />
						{recordTypes[category].label}
						<ChevronRightIcon />
						{recordTypes[category].subcategories[subcategory].label}
					</Typography>

					{showLeaseSwitch && (
						<Typography component="div" className="leaseSwitch">
							<Grid component="label" container alignItems="center" spacing={1}>
								<Grid
									className={isLease ? 'switchBtn' : 'switchBtn active'}
									item
								>
									Sales Comp
								</Grid>
								<Grid item>
									<AntSwitch
										checked={isLease}
										onChange={toggleCompType}
										name="isLease"
									/>
								</Grid>
								<Grid
									className={isLease ? 'switchBtn active' : 'switchBtn'}
									item
								>
									Lease Comp
								</Grid>
							</Grid>
						</Typography>
					)}
					<Button
						autoFocus
						color="inherit"
						disabled={loadingNow}
						onClick={saveNowCallback}
					>
						save
					</Button>
					<div className="saveDivider" />
					<Button
						autoFocus
						color="inherit"
						disabled={loading}
						onClick={saveCallback}
					>
						save & exit
					</Button>
				</Toolbar>
			</AppBar>
			<Box bgcolor="#fff" pb={4} pt={11} pl={5} pr={5}>
				<Form isLease={isLease} record={{ category, subcategory }} />
			</Box>
		</Dialog>
	);
};

export default withRecordForm(FormModal);
