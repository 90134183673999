import Attachments from './Attachments';
import Basic from './Basic';
import Context from './Context';
import FixedCol from './FixedCol';
import MultiRow from './MultiRow';
import React from 'react';

const typeComponents = {
	Attachments,
	Basic,
	FixedCol,
	MultiRow,
};

const init = (initialState) => ({
	customFields: [],
	...initialState,
});

export const reducer = (state, { payload, type }) => {
	switch (type) {
		case 'addCustomField':
			return {
				...state,
				customFields: [...(state.customFields || []), payload],
			};
		case 'removeCustomField':
			return {
				...state,
				customFields: (state.customFields || []).filter(
					({ key }) => key !== payload
				),
			};
		case 'setValue':
			return {
				...state,
				values: {
					...state.values,
					[payload.key]: payload.value,
				},
			};
		case 'addEntry':
			return {
				...state,
				values: [...(state.values || []), payload],
			};
		case 'removeEntry':
			return {
				...state,
				values: (state.values || []).filter(
					(entry, index) => index !== payload
				),
			};
		case 'setEntryValues':
			return {
				...state,
				values: (state.values || []).map((entry, index) =>
					index === payload.index ? { ...entry, ...payload.values } : entry
				),
			};
		default:
			throw new Error('unsupported action');
	}
};

const Section = React.memo(
	({ isLease, initialState, onChange, section, formState }) => {
		const [state, dispatch] = React.useReducer(reducer, initialState, init);
		const SectionComponent = typeComponents[section.type];
		const actions = React.useMemo(
			() => ({
				addCustomField: (payload) =>
					dispatch({ payload, type: 'addCustomField' }),
				removeCustomField: (payload) =>
					dispatch({ payload, type: 'removeCustomField' }),
				setValue: (payload) => dispatch({ payload, type: 'setValue' }),
				setEntryValues: (payload) =>
					dispatch({ payload, type: 'setEntryValues' }),
				addEntry: (payload) => dispatch({ payload, type: 'addEntry' }),
				removeEntry: (payload) => dispatch({ payload, type: 'removeEntry' }),
			}),
			[]
		);
		const context = React.useMemo(
			() => ({
				actions,
				...section,
				fields: section.fields
					.concat(
						state.customFields.map((field) => ({ ...field, isCustom: true }))
					)
					.map((field) => ({ ...field, label: field.label || field.key })),
			}),
			[actions, state.customFields, section]
		);

		React.useEffect(() => onChange(state), [onChange, state]);

		return (
			<Context.Provider value={context}>
				<SectionComponent
					isLease={isLease}
					values={state.values}
					formState={formState}
				/>
			</Context.Provider>
		);
	}
);

export default Section;
