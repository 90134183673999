import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import FilterInput from '../FilterInput';
import CloseIcon from '@material-ui/icons/Close';
import DynamicInput from '../DynamicInput';
import './filterDrawer.css';

const useStyles = makeStyles({
	list: {
		width: '50%',
		minWidth: 500,
	},
	fullList: {
		width: '50%',
		minWidth: 500,
	},
});

const uniq = (array) => {
	return array.filter(
		(e, i) => array.findIndex((a) => a['value'] === e['value']) === i
	);
};

const getOptions = (records, sectionKey, key) => {
	const allOptions = records.map((r) => ({
		value: ((r[sectionKey] || {}).values || {})[key] || '',
	}));
	const options = uniq(allOptions).filter((v) => v);
	return options;
};

const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

const getPlantings = (records) => {
	const allOptions = records
		.map((r) => ((r.Plantings || {}).values || []).map((p) => p.type))
		.flat();
	const options = allOptions.filter(onlyUnique).filter((v) => v);
	return options.map((value) => ({ value }));
};

const getSurfaceWater = (records) => {
	const allOptions = records
		.map(
			(r) =>
				((r.DairySiteInfo || r.OpenSiteInfo || r.PlantingsSiteInfo || {})
					.values || {})['surfaceWater']
		)
		.flat();
	const options = allOptions.filter(onlyUnique).filter((v) => v);
	return options.map((value) => ({ value }));
};

const getValues = (records, key, sectionKey) => {
	if (!records) {
		return [];
	}
	let options = [];
	switch (key) {
		case 'apn':
			options = getOptions(records, sectionKey, key);
			break;
		case 'city':
			options = getOptions(records, sectionKey, key);
			break;
		case 'county':
			options = getOptions(records, sectionKey, key);
			break;
		case 'address':
			options = getOptions(records, sectionKey, key);
			break;
		case 'plantings':
			options = getPlantings(records);
			break;
		default:
			options = [];
	}
	return options;
};

const minPriceInput = ['Min $', 'money', false];
const maxPriceInput = ['Max $', 'money', false];

const minSizeAcres = ['Min Acres', 'number', false];
const maxSizeAcres = ['Max Acres', 'number', false];

const minSqft = ['Min Sqft', 'number', false];
const maxSqft = ['Max Sqft', 'number', false];

export default function FilterDrawer(props) {
	const classes = useStyles();
	const { filters, records } = props;
	const apns = getValues(records, 'apn', 'PropertyInfo');
	const cities = getValues(records, 'city', 'PropertyInfo');
	const plantings = getValues(records, 'plantings');
	const counties = getValues(records, 'county', 'PropertyInfo');
	const addresses = getValues(records, 'address', 'PropertyInfo');
	const water = getSurfaceWater(records, 'surfaceWater');
	const { MinPrice, MaxPrice, MinAcres, MaxAcres, MinSqft, MaxSqft } = filters;
	const [state, setState] = React.useState({
		left: false,
		readyToSubmit: true,
	});
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const handleFilterChange = (filterKey, value) => {
		const key = props.getDbKey(filterKey);
		const newFilters = {
			...filters,
			[key]: value,
		};
		props.setFilters(newFilters);
	};

	const deleteFilter = (keyA, keyB) => {
		const currentFilters = { ...props.filters };
		const A = props.getDbKey(keyA);
		const B = props.getDbKey(keyB);
		const newFilters = {
			...currentFilters,
			[A]: '',
			[B]: '',
		};
		props.setFilters(newFilters);
	};

	const list = (anchor) => {
		return (
			<div
				className={clsx(classes.list, {
					[classes.fullList]: anchor === 'top' || anchor === 'bottom',
				})}
				role="presentation"
			>
				<div className="FilterDrawer-top">
					<div className="FilterDrawer-title">
						<h4>Filters</h4>
					</div>
					<div className="FilterDrawer-closeBtn">
						<Button>
							<CloseIcon onClick={toggleDrawer('left', false)} />
						</Button>
					</div>
				</div>
				<List>
					<ListItem className="FilterDrawerItem FilterDrawer-priceRange">
						<div className="filterTitle">Price</div>
						<div className="FilterDrawer-priceRangeInputs FilterDrawer-inputs">
							<DynamicInput
								handler={handleFilterChange}
								value={MinPrice}
								input={minPriceInput}
								placeholder="Sales Min $"
							/>
							<DynamicInput
								handler={handleFilterChange}
								value={MaxPrice}
								input={maxPriceInput}
								placeholder="Sales Max $"
							/>
							{(Boolean(MinPrice) || Boolean(MaxPrice)) && (
								<Button onClick={deleteFilter.bind(this, 'Min $', 'Max $')}>
									Clear
								</Button>
							)}
						</div>
					</ListItem>
					<ListItem className="FilterDrawerItem FilterDrawer-acres">
						<div className="filterTitle">Acreage</div>
						<div className="FilterDrawer-acresInputs FilterDrawer-inputs">
							<DynamicInput
								handler={handleFilterChange}
								value={MinAcres}
								input={minSizeAcres}
								placeholder="Min Acres"
							/>
							<DynamicInput
								handler={handleFilterChange}
								value={MaxAcres}
								input={maxSizeAcres}
								placeholder="Max Acres"
							/>
							{(Boolean(MinAcres) || Boolean(MaxAcres)) && (
								<Button
									onClick={deleteFilter.bind(this, 'Min Acres', 'Max Acres')}
								>
									Clear
								</Button>
							)}
						</div>
					</ListItem>
					<ListItem className="FilterDrawerItem FilterDrawer-sqft">
						<div className="filterTitle">Total Sqft</div>
						<div className="FilterDrawer-acresInputs FilterDrawer-inputs">
							<DynamicInput
								handler={handleFilterChange}
								value={MinSqft}
								input={minSqft}
								placeholder="Min Sqft"
							/>
							<DynamicInput
								handler={handleFilterChange}
								value={MaxSqft}
								input={maxSqft}
								placeholder="Max Sqft"
							/>
							{(Boolean(MinSqft) || Boolean(MaxSqft)) && (
								<Button
									onClick={deleteFilter.bind(this, 'Min Sqft', 'Max Sqft')}
								>
									Clear
								</Button>
							)}
						</div>
					</ListItem>
					<FilterInput
						filters={props.filters}
						inputKey="apn"
						title="APN"
						options={apns}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="city"
						title="City"
						options={cities}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="county"
						title="County"
						options={counties}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="address"
						title="Address"
						options={addresses}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						id="datePicker"
						inputKey="recordingDate"
						title="Date of Sale"
						type="date"
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="plantings"
						title="Plantings"
						options={plantings}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="water"
						title="Water District"
						options={water}
						handler={handleFilterChange}
					/>
					<FilterInput
						filters={props.filters}
						inputKey="includeOG"
						options={plantings}
						handler={handleFilterChange}
					/>
				</List>
				<Divider />
			</div>
		);
	};

	return (
		<div className="FilterDrawer">
			<React.Fragment>
				<Button
					onClick={toggleDrawer('left', true)}
					className="FilterDrawer-toggle"
				>
					{'Filters'}
				</Button>
				<Drawer
					anchor={'left'}
					open={state['left']}
					onClose={toggleDrawer('left', false)}
				>
					{list('left')}
				</Drawer>
			</React.Fragment>
		</div>
	);
}
