import { recordTypes } from '@hower/shared';
import { useAsyncCallback } from 'async-react-hooks';
import { withRecordForm } from '../../RecordForm';
import './editModal.css';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ToastServive from 'react-material-toast';

const toast = ToastServive.new({
	place: 'topRight',
	duration: 2,
	maxCount: 4,
});

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const FormModal = ({
	Form,
	comp,
	handleClose,
	handleSave,
	handleSaveNow,
	handleDelete,
	open,
}) => {
	const classes = useStyles();
	const [saveCallback, { loading }] = useAsyncCallback(async () => {
		await handleSave();
		handleClose();
	}, [handleClose, handleSave]);

	const [deleteOpen, setDeleteOpen] = useState(false);

	const handleDeleteOpen = () => {
		setDeleteOpen(true);
	};

	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};

	const [saveNowCallback, { loadingNow }] = useAsyncCallback(async () => {
		try {
			await handleSaveNow();
			toast.success('Changes Saved');
		} catch (error) {
			toast.error('Saved Failed. Try again.');
			console.log(`error in saveNowCallback:`, error);
		}
	}, [handleSaveNow]);

	const deleteRecord = () => {
		handleDelete();
		handleClose();
	};

	const isLease = comp.isLease ? true : false;

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			fullScreen
			onClose={handleClose}
			open={open}
		>
			<AppBar>
				<Backdrop open={loading}>
					<CircularProgress />
				</Backdrop>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					{comp.category && recordTypes[comp.category] && (
						<Typography variant="h6" className={classes.title}>
							Edit Comp
							<ChevronRightIcon />
							{recordTypes[comp.category].label}
							<ChevronRightIcon />
							{recordTypes[comp.category].subcategories[comp.subcategory].label}
						</Typography>
					)}
					<Button
						autoFocus
						color="inherit"
						disabled={loadingNow}
						onClick={saveNowCallback}
					>
						save
					</Button>
					<div className="saveDivider" />
					<Button
						autoFocus
						color="inherit"
						disabled={loading}
						onClick={saveCallback}
					>
						save & exit
					</Button>
					<div className="saveDivider" />
					<div className="deleteRecordBtn">
						<Button
							autoFocus
							color="inherit"
							disabled={loading}
							onClick={handleDeleteOpen}
						>
							delete record
						</Button>
					</div>
					<Dialog
						onClose={handleDeleteClose}
						aria-labelledby="simple-dialog-title"
						open={deleteOpen}
					>
						<DialogTitle>
							Are you sure you want to delete this record?
						</DialogTitle>
						<div className="deleteConfirmOptions">
							<Button
								autoFocus
								color="inherit"
								disabled={loading}
								onClick={deleteRecord}
							>
								Yes
							</Button>
							<Button
								autoFocus
								color="inherit"
								disabled={loading}
								onClick={handleDeleteClose}
							>
								No, Cancel
							</Button>
						</div>
					</Dialog>
				</Toolbar>
			</AppBar>
			<Box bgcolor="grey.200" pb={4} pt={11}>
				<Form isLease={isLease} record={comp} />
			</Box>
		</Dialog>
	);
};

export default withRecordForm(FormModal);
