import Box from '@material-ui/core/Box';
import Input from './Input';
import React from 'react';

const Field = React.memo(
	({ addon = null, fieldKey, inTable, label, setValue, isneg, ...props }) => (
		<Box display="flex" mt={inTable ? -0.2 : 0} mb={inTable ? -0.8 : 0}>
			<Box flexGrow={1}>
				<Input
					{...props}
					isneg={isneg}
					label={inTable ? null : label || fieldKey}
					margin={inTable ? 'none' : 'dense'}
					setValue={(value) => setValue({ key: fieldKey, value })}
					variant={inTable ? 'standard' : 'outlined'}
					InputProps={inTable ? { style: { fontSize: '0.875rem' } } : null}
				/>
			</Box>
			{addon && (
				<Box flexShrink={1} ml={0.25} mt={0.5}>
					{addon}
				</Box>
			)}
		</Box>
	)
);

export default Field;
