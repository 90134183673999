import React from 'react';
import Button from '@material-ui/core/Button';
import { Storage } from 'aws-amplify';

export default class ViewButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = { link: null };
	}

	async componentDidMount() {
		try {
			const link = await Storage.get(this.props.attachment.s3Key);
			this.setState({ link });
		} catch (error) {
			console.log(`error in ViewButton get attachment:`, error);
		}
	}

	render() {
		return (
			<Button href={this.state.link} target="_blank" color="primary">
				View
			</Button>
		);
	}
}
