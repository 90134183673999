import React from 'react';
import './map.css';
import CustomMarker from './CustomMarker';
const { compose, withProps } = require('recompose');
const { withScriptjs, withGoogleMap, GoogleMap } = require('react-google-maps');
const {
	MarkerWithLabel,
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');
const {
	MarkerClusterer,
} = require('react-google-maps/lib/components/addons/MarkerClusterer');

const calc = (props, mkrs) => {
	const { compsAdded } = props;
	const l = mkrs.length;
	return {
		index: 0,
		text: !compsAdded
			? `${l} Total`
			: mkrs.reduce(
					(acc, v, i) => `${acc}${i === 0 ? '' : ','} ${v.title}`,
					''
			  ),
	};
};

export const GMap = compose(
	withProps({
		googleMapURL:
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYQ4giBbXprGji5z6sGelI7NjnzGxGnpg&v=3.exp&libraries=geometry,drawing,places',
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: (
			<div
				style={{
					height: `80vh`,
					width: '95%',
					maxHeight: '700px',
					margin: 'auto',
					marginTop: '100px',
					marginBottom: '50px',
				}}
			/>
		),
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)((props) => {
	return (
		<GoogleMap defaultZoom={8} defaultCenter={{ lat: 36.3302, lng: -119.2921 }}>
			<MarkerClusterer
				calculator={calc.bind(this, props)}
				averageCenter
				enableRetinaIcons
				clusterClass="clusterClass"
				gridSize={50}
			>
				{props.compsAdded &&
					props.reportCompMarkers.map((marker, i) => (
						<MarkerWithLabel
							key={`markerKey-${i}`}
							position={{
								lat: Number(marker.latitude),
								lng: Number(marker.longitude),
							}}
							title={`${marker.index}`}
							labelAnchor={{ x: 15, y: 45 }}
							labelClass="markerLabelContainerClass"
						>
							<div className="markerLabelClass">{marker.index}</div>
						</MarkerWithLabel>
					))}
				{!props.compsAdded &&
					props.markers.map((marker, i) => (
						<CustomMarker index={i} marker={marker} />
					))}
			</MarkerClusterer>
		</GoogleMap>
	);
});
