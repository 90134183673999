import * as React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import './dynamicInput.css';

let basicMaskOptions = {
	prefix: '',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ',',
	allowDecimal: true,
	decimalSymbol: '.',
	decimalLimit: 2,
	integerLimit: 14,
	allowNegative: false,
	allowLeadingZeroes: false,
};

const moneyMaskOptions = {
	...basicMaskOptions,
	prefix: '$',
};

const percentMaskOptions = {
	...basicMaskOptions,
	suffix: '%',
	integerLimit: 3,
};

const sfMaskOptions = {
	...basicMaskOptions,
	suffix: ' sf',
	integerLimit: 10,
};

const currencyMask = createNumberMask(moneyMaskOptions);
const percentMask = createNumberMask(percentMaskOptions);
const sfMask = createNumberMask(sfMaskOptions);

export default class DynamicInput extends React.Component {
	constructor(props) {
		super(props);

		let input = this.props.input || [];
		const [name, type, userAdded] = input;

		this.state = {
			focused: false,
			type,
			name,
			date: moment(),
			value: '',
			userAdded,
			checked: false,
			showDelete: false,
		};
	}

	updateDate = (momentDate) => {
		const date = moment(momentDate).format('MM/DD/YYYY');
		this.setState({ date: momentDate });
		this.props.handler(this.state.name, date);
	};

	updateInput = (e) => {
		const val = e.target.value;
		this.setState({ value: val });
		this.props.handler(this.state.name, val);
	};

	updateCheckbox = (e) => {
		const bool = e.target.checked;
		this.setState({ checked: bool });
		this.props.handler(this.state.name, bool);
	};

	showDelete = () => {
		this.setState({ showDelete: true });
	};

	hideDelete = () => {
		this.setState({ showDelete: false });
	};

	render() {
		let input = null;
		const { boxKey, inputIndex } = this.props;
		const { name, type, userAdded, showDelete } = this.state;

		switch (this.state.type) {
			case 'date':
				input = (
					<div className="DynamicInput-datePicker">
						<h4>{name}</h4>
						<SingleDatePicker
							numberOfMonths={1}
							date={this.state.date}
							onDateChange={this.updateDate}
							isOutsideRange={() => false}
							focused={this.state.focused}
							onFocusChange={({ focused }) => this.setState({ focused })}
							id={`${name}-date`}
						/>
					</div>
				);
				break;
			case 'percent':
				input = (
					<div className="DynamicInput-input">
						<MaskedInput
							placeholder={name}
							mask={percentMask}
							name={name}
							onChange={this.updateInput}
						/>
					</div>
				);
				break;
			case 'money':
				input = (
					<div className="DynamicInput-input">
						<MaskedInput
							placeholder={this.props.placeholder || name}
							mask={currencyMask}
							value={this.props.value}
							name={name}
							onChange={this.updateInput}
						/>
					</div>
				);
				break;
			case 'textarea':
				input = (
					<div className="DynamicInput-input">
						<textarea
							className="DynamicInput-input"
							name={name}
							onChange={this.updateInput}
						/>
					</div>
				);
				break;
			case 'sf':
				input = (
					<div className="DynamicInput-input">
						<MaskedInput
							placeholder={name}
							mask={sfMask}
							name={name}
							onChange={this.updateInput}
						/>
					</div>
				);
				break;
			case 'bool':
				input = (
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.checked}
								onChange={this.updateCheckbox}
								color="primary"
							/>
						}
						label={name}
					/>
				);
				break;
			default:
				input = (
					<input
						placeholder={name}
						className="DynamicInput-input"
						type={type}
						value={this.props.value}
						name={name}
						onChange={this.updateInput}
					/>
				);
		}

		return (
			<div className="DynamicInput">
				<div
					onMouseEnter={this.showDelete}
					onMouseLeave={this.hideDelete}
					className={`${this.state.type}InputWrapper DynamicInput-Wrapper`}
				>
					{input}
					{showDelete && userAdded && (
						<DeleteOutlinedIcon
							onClick={this.props.removeField.bind(this, boxKey, inputIndex)}
							style={{ color: '#ff4d4d' }}
						/>
					)}
				</div>
			</div>
		);
	}
}
