import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

const DateInput = ({ setValue, value, variant, ...props }) => (
	<KeyboardDatePicker
		clearable
		format="MM/DD/YYYY"
		fullWidth
		inputVariant={variant}
		KeyboardButtonProps={{ edge: 'end' }}
		onChange={(momentValue, value) => setValue(value)}
		size="small"
		value={value || null}
		{...props}
	/>
);

export default DateInput;
