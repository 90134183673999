import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(0),
	},
}));

const CheckboxInput = ({ label, setValue, value }) => {
	const classes = useStyles();

	return (
		<FormControl margin="dense" size="small">
			<FormControlLabel
				classes={classes}
				control={
					<Checkbox
						checked={Boolean(value)}
						onChange={(event) => setValue(event.target.checked)}
					/>
				}
				label={label}
			/>
		</FormControl>
	);
};
export default CheckboxInput;
