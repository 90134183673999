import React from 'react';
import './choices.css';

export default class Choices extends React.Component {
	render() {
		const options = this.props.options;

		return (
			<div className="Choices">
				<select onChange={this.props.handler}>
					{options.map((choice, i) => (
						<option key={`${choice.key}Choice-${i}`} value={choice.value}>
							{choice.label}
						</option>
					))}
				</select>
			</div>
		);
	}
}
