import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { S3Image } from 'aws-amplify-react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditModal from '../EditModal';
import AttachmentsModal from '../AttachmentsModal';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { fieldTypes } from '@hower/shared';
import './compCard.css';

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
});

export default function MediaCard(props) {
	const [open, setOpen] = React.useState(false);
	const classes = useStyles();
	const comp = props.comp;
	let dataKey = 'TransactionalData';

	switch (comp.subcategory) {
		case 'dairy':
			dataKey = 'DairyTransactionalData';
			break;
		case 'permanentPlantings':
			dataKey = 'PlantingsTransactionalData';
			break;
		case 'openGround':
			dataKey = 'OpenTransactionalData';
			break;
		case 'land':
			dataKey = 'LandTransactionalData';
			break;
		default:
			dataKey = 'TransactionalData';
	}

	const updateReport = () => {
		if (props.isInReport) {
			props.removeFromReport(props.comp);
		} else {
			props.addToReport(props.comp);
		}
	};

	const attachments = (comp.Attachments || {}).values || [];

	return (
		<Card className={`${classes.root} CompCard`}>
			<div className="compCardBody">
				<div className="compCardMedia">
					{comp.MainImage.values && comp.MainImage.values[0] && (
						<S3Image imgKey={comp.MainImage.values[0].s3Key} />
					)}
				</div>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{comp.PropertyInfo.values && comp.PropertyInfo.values.address}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						{comp.PropertyInfo &&
							comp.PropertyInfo.values &&
							comp.PropertyInfo.values.city}
						,CA
					</Typography>
					<Typography gutterBottom variant="h5" component="h4">
						Sales Price:{' '}
						{comp[dataKey] &&
							comp[dataKey].values &&
							fieldTypes.money.format(comp[dataKey].values.salePrice)}
					</Typography>
				</CardContent>
			</div>
			<CardActions className="CompCard-actions">
				<IconButton onClick={() => setOpen(true)}>
					<EditIcon style={{ color: '#7e8da5' }} />
				</IconButton>
				<EditModal comp={comp} handleClose={() => setOpen(false)} open={open} />
				{attachments.length > 0 && (
					<AttachmentsModal
						address={(comp.PropertyInfo.values || {}).address}
						attachments={attachments}
					/>
				)}
				<Button size="small" color="primary" onClick={updateReport}>
					{props.isInReport ? (
						<CheckBoxIcon style={{ fontSize: '33px', color: 'dodgerblue' }} />
					) : (
						<CheckBoxOutlineBlankIcon
							style={{ fontSize: '33px', color: '#abacad' }}
						/>
					)}
				</Button>
			</CardActions>
		</Card>
	);
}
