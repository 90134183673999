import * as React from 'react';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { Button } from 'react-bootstrap';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import './home.css';

const corpSubcategories = [
	'Apartment',
	'Retail',
	'Industrial',
	'Office',
	'Land',
	'Shopping',
	'Self Storage',
	'Restaurant',
	'Medical',
];
const agSubcategories = ['Dairy', 'Open Ground', 'Permanent Plantings'];

export default class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			date: moment(),
			focused: false,
			showLocInputError: true,
			subcategory: '',
			category: 'Commercial',
		};
	}

	handleSubcategoryChange = (event) => {
		const sc = event.target.value;
		this.props.updateLocation('subcategory', sc);
		this.setState({ subcategory: sc });
	};

	handleCatSelect = (cat) => {
		this.props.updateLocation('category', cat);
		this.setState({ category: cat, subcategory: '' });
	};

	render() {
		const cat = this.state.category;
		const sc = this.state.subcategory;

		return (
			<div className="Home Home-bg">
				<div className="Home-content">
					<div className="Home-ctaContainer">
						<ButtonGroup
							color="primary"
							aria-label="outlined primary button group"
						>
							<Button
								variant="outline-secondary"
								className={
									cat === 'Commercial'
										? 'Home-catBtn Home-catSelected'
										: 'Home-catBtn'
								}
								color="secondary"
								onClick={() => this.handleCatSelect('Commercial')}
							>
								Commercial
							</Button>
							<Button
								variant="outline-secondary"
								className={
									cat === 'Ag' ? 'Home-catBtn Home-catSelected' : 'Home-catBtn'
								}
								color="secondary"
								onClick={() => this.handleCatSelect('Ag')}
							>
								Ag
							</Button>
						</ButtonGroup>

						<FormControl variant="outlined" style={{ minWidth: 120 }}>
							<InputLabel htmlFor="subcategory-select-label">Type</InputLabel>
							<Select
								defaultValue={sc || 'DEFAULT'}
								onChange={this.handleSubcategoryChange}
								labelId="subcategory-select-label"
								id="subcategory-select"
								label="Type"
							>
								<option value="DEFAULT" disabled>
									Select a Subcategory
								</option>
								{cat === 'Commercial'
									? corpSubcategories.map((opt, i) => (
											<option
												key={`commOption-${i}`}
												value={opt}
												name={opt}
												className={`${
													sc === opt
														? 'Home-option Home-optionSelected'
														: 'Home-option'
												}`}
											>
												{opt}
											</option>
									  ))
									: agSubcategories.map((opt, i) => (
											<option
												key={`agOption-${i}`}
												value={opt}
												name={opt}
												className={`${
													sc === opt
														? 'Home-option Home-optionSelected'
														: 'Home-option'
												}`}
											>
												{opt}
											</option>
									  ))}
							</Select>
						</FormControl>
						<Link to="/viewData">
							<Button
								variant="outline-secondary"
								style={{ height: '56px' }}
								disabled={!cat || !sc}
							>
								View Comps
							</Button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
