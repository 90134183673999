import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Context from './Context';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Choices from '../../common/Choices';
import React from 'react';
import TextField from '@material-ui/core/TextField';

const options = [
	{ value: 'text', label: 'Text' },
	{ value: 'number', label: 'Number' },
	{ value: 'date', label: 'Date' },
	{ value: 'money', label: 'Money' },
	{ value: 'bool', label: 'Yes/No' },
	{ value: 'percent', label: 'Percent' },
	{ value: 'cfj', label: 'CFJ' },
];

const AddCustomField = () => {
	const { actions, fields } = React.useContext(Context);
	const [open, setOpen] = React.useState(false);
	const [key, setKey] = React.useState('');
	const [type, setType] = React.useState('text');
	const [cfjSelected, setCFJSelected] = React.useState(false);
	const [nextCFJ, setNextCFJ] = React.useState('');
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const getNextCFJ = () => {
		return `CFJ${fields
			.map((v) => v.key)
			.reduce((a, v) => a + v.includes('CFJ'), 0)}`;
	};
	const handleKeyChange = (event, val) => {
		let key = val ? val : event.target.value;
		setKey(key);
	};
	const handleChoiceChange = (event) => {
		let type = event.target.value;
		if (type === 'cfj') {
			const inputVal = getNextCFJ();
			setNextCFJ(inputVal);
			setCFJSelected(true);
			handleKeyChange(null, inputVal);
			type = 'money';
		}
		setType(type);
	};
	const handleSave = () => {
		handleClose();
		actions.addCustomField({ key, type });
		setKey('');
		setCFJSelected(false);
		setType('text');
	};
	const error = fields.find((existingField) => existingField.key === key)
		? 'Field with that name already exists.'
		: null;
	const fieldVal = cfjSelected ? nextCFJ : key;

	return (
		<>
			<IconButton onClick={handleOpen} title="Add Custom Field">
				<AddIcon />
			</IconButton>
			<Dialog
				fullWidth
				maxWidth="xs"
				onClose={() => setOpen(false)}
				open={open}
			>
				<DialogTitle>Add Custom Field</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						error={Boolean(error)}
						fullWidth
						helperText={error}
						label="Field Name"
						margin="normal"
						onChange={(event) => handleKeyChange(event)}
						value={fieldVal}
						variant="outlined"
					/>
					<Choices
						options={options}
						handler={(event) => handleChoiceChange(event)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						disabled={!key || !type || Boolean(error)}
						onClick={handleSave}
						color="primary"
					>
						Add Field
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddCustomField;
