import Context from '../Context';
import ConfirmDelete from './ConfirmDelete';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Thumbnail from './Thumbnail';
import { makeStyles } from '@material-ui/core/styles';
import ViewButton from './ViewButton';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
	},
}));

const Attachment = React.memo(({ attachment, index }) => {
	const classes = useStyles();
	const { actions } = React.useContext(Context);
	const setName = React.useCallback(
		(event) =>
			actions.setEntryValues({ index, values: { name: event.target.value } }),
		[actions, index]
	);

	return (
		<ListItem className={classes.root} divider>
			{attachment.uploading && (
				<LinearProgress
					className={classes.progress}
					value={attachment.progress}
					variant="determinate"
				/>
			)}
			<Thumbnail attachment={attachment} />
			<TextField
				fullWidth
				label="Name"
				onChange={setName}
				size="small"
				value={attachment.name}
			/>
			<ViewButton attachment={attachment} />
			<ListItemSecondaryAction>
				<ConfirmDelete
					buttonProps={{ edge: 'end' }}
					onConfirm={() => actions.removeEntry(index)}
					id="attachmentDeleteBtn"
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
});

export default Attachment;
