import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Context from './Context';
import Field from './Field';
import MaterialTable from 'material-table';
import React from 'react';
import RemoveCustomField from './RemoveCustomField';
import Wrapper from './Wrapper';
import { fieldTypes } from '@hower/shared';

const columnTypes = {
	bool: 'boolean',
	date: 'date',
	money: 'numeric',
	number: 'numeric',
	percent: 'numeric',
	sf: 'numeric',
};

const buildTableColumns = (fields) =>
	fields.map(({ isCustom, key, label, type }, index) => ({
		editComponent: (props) => (
			<Field
				autoFocus={index === 0}
				fieldKey={key}
				inTable
				setValue={(payload) => props.onChange(payload.value)}
				type={type}
				value={props.value}
			/>
		),
		field: key,
		render: (rowData) => fieldTypes[type].format(rowData[key]),
		sorting: !isCustom, // disable sorting for custom columns to prevent crash when removing a field
		title: isCustom ? (
			<Box alignItems="center" display="flex" wrap="false">
				<div>{label}</div>
				<RemoveCustomField fieldKey={key} size="small" />
			</Box>
		) : (
			label
		),
		type: columnTypes[type],
		width: 120,
	}));

const staticTableProps = {
	components: {
		Container: Box,
	},
	options: {
		draggable: false,
		search: false,
		tableLayout: 'fixed',
		toolbar: false,
	},
};

const useTableProps = () => {
	const { actions, customFields, fields } = React.useContext(Context);
	const columns = buildTableColumns(fields, customFields);

	return {
		columns,
		editable: {
			onRowAdd: async (newEntry) => {
				actions.addEntry(newEntry);
			},
			onRowDelete: async ({ index }) => {
				actions.removeEntry(index);
			},
			onRowUpdate: async ({ index, ...values }) => {
				actions.setEntryValues({
					index,
					values,
				});
			},
		},
		...staticTableProps,
	};
};

const MultiRow = ({ values = [] }) => {
	const tableRef = React.useRef();
	const addRow = React.useCallback(
		() => tableRef.current.setState({ showAddRow: true }),
		[tableRef]
	);
	const tableProps = useTableProps();

	return (
		<Wrapper
			action={
				<Button color="primary" key="row" onClick={addRow}>
					Add Row
				</Button>
			}
		>
			<MaterialTable
				data={values.map((entry, index) => ({ ...entry, index }))}
				tableRef={tableRef}
				{...tableProps}
			/>
		</Wrapper>
	);
};

export default MultiRow;
