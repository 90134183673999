import CheckboxInput from './CheckboxInput';
import DateInput from './DateInput';
import MoneyInput from './MoneyInput';
import PercentInput from './PercentInput';
import React from 'react';
import SquareFootInput from './SquareFootInput';
import TextInput from './TextInput';

const inputsByType = {
	bool: CheckboxInput,
	date: DateInput,
	money: MoneyInput,
	number: (props) => <TextInput {...props} type="number" />,
	percent: PercentInput,
	sf: SquareFootInput,
	text: TextInput,
	textarea: (props) => <TextInput {...props} multiline rows={5} />,
};

const Input = ({ type, ...props }) => {
	const InputComponent = inputsByType[type] || TextInput;

	return <InputComponent {...props} />;
};

export default Input;
