import { Storage } from 'aws-amplify';
import Attachment from './Attachment';
import Context from '../Context';
import Dropzone from './Dropzone';
import List from '@material-ui/core/List';
import React from 'react';
import Wrapper from '../Wrapper';

const uploadImage = async ({ file, s3Key }, setProgress) => {
	try {
		await Storage.put(s3Key, file, {
			contentType: file.type,
			progressCallback: (progress) => {
				setProgress(
					progress.total ? (progress.loaded / progress.total) * 100 : 0
				);
			},
		});
	} catch (error) {
		console.log('error on Storage.put:', error);
	}
};

const Attachments = ({ values: attachments = [] }) => {
	const { actions, multiple } = React.useContext(Context);
	React.useEffect(() => {
		attachments.forEach(async (attachment, index) => {
			if (attachment.file && !attachment.uploading) {
				actions.setEntryValues({
					index,
					values: { progress: 0, uploading: true },
				});
				await uploadImage(attachment, (progress) =>
					actions.setEntryValues({
						index,
						values: { progress },
					})
				);
				actions.setEntryValues({
					index,
					values: {
						file: undefined,
						progress: undefined,
						uploading: undefined,
					},
				});
			}
		});
	}, [actions, attachments]);

	return (
		<Wrapper>
			{(attachments.length < 1 || multiple) && <Dropzone />}
			<List disablePadding>
				{attachments.map((attachment, index) => (
					<Attachment attachment={attachment} index={index} key={index} />
				))}
			</List>
		</Wrapper>
	);
};

export default Attachments;
