import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import TextInput from './TextInput';

const Percent = (props) => (
	<TextInput
		{...props}
		type="number"
		InputProps={{
			...props.InputProps,
			startAdornment: <InputAdornment position="start">%</InputAdornment>,
		}}
	/>
);

export default Percent;
