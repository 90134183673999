import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import React from 'react';
import { Storage } from 'aws-amplify';

const useS3Url = (s3Key) => {
	const [url, setUrl] = React.useState();

	React.useEffect(() => {
		if (!s3Key) return;

		Storage.get(s3Key)
			.then((url) => setUrl(url))
			.catch(
				(error) => {
					console.log(error);
				},
				[s3Key]
			);
	});

	return url;
};

const Thumbnail = React.memo(({ attachment: { file, name, s3Key } }) => {
	const hasFile = file && 'lastModified' in file;
	const s3Url = useS3Url(hasFile ? null : s3Key);
	const previewUrl = hasFile ? URL.createObjectURL(file) : s3Url;
	const onLoad = previewUrl ? () => URL.revokeObjectURL(previewUrl) : null;

	return (
		<ListItemAvatar>
			<Avatar alt={name} onLoad={onLoad} src={previewUrl} variant="square">
				<ImageIcon />
			</Avatar>
		</ListItemAvatar>
	);
});

export default Thumbnail;
