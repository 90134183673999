import AddCustomField from './AddCustomField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Context from './Context';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		border: '1px solid #e7e7e7',
	},
	title: {
		position: 'absolute',
		top: '-12px',
		left: '18px',
		background: '#fff',
		padding: '0 4px',
		color: '#5e6c6d',
	},
	header: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		'& .MuiCardHeader-action': {
			marginBottom: theme.spacing(-1),
		},
	},
}));

const Wrapper = ({ action = null, children }) => {
	const { enableCustomFields, title } = React.useContext(Context);
	const classes = useStyles();
	const actions = (
		<React.Fragment>
			{action}
			{enableCustomFields && <AddCustomField />}
		</React.Fragment>
	);

	return (
		<div className={classes.container}>
			<div className={classes.title}>{title}</div>
			<Card style={{ height: '100%' }}>
				<CardHeader
					action={actions}
					className={classes.header}
					titleTypographyProps={{ variant: 'h6' }}
				/>
				{children}
			</Card>
		</div>
	);
};
export default Wrapper;
