import Button from '@material-ui/core/Button';
import Context from './Context';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	dense: {
		marginBottom: theme.spacing(-1),
		marginLeft: theme.spacing(0.25),
		marginRight: theme.spacing(0.25),
		marginTop: theme.spacing(-1),
		padding: theme.spacing(1),
	},
}));

const RemoveCustomField = ({ fieldKey, size = 'medium' }) => {
	const classes = useStyles();
	const { actions } = React.useContext(Context);
	const [open, setOpen] = React.useState(false);
	const handleRemove = () => {
		setOpen(false);
		actions.removeCustomField(fieldKey);
	};

	return (
		<>
			<IconButton
				className={size === 'small' ? classes.dense : null}
				onClick={() => setOpen(true)}
				size={size}
				tabIndex="-1"
				title="Remove Custom Field"
			>
				<DeleteIcon />
			</IconButton>
			<Dialog
				fullWidth
				maxWidth="xs"
				onClose={() => setOpen(false)}
				open={open}
			>
				<DialogTitle>
					Delete <Typography variant="overline">{fieldKey}</Typography>?
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Keep</Button>
					<Button onClick={handleRemove} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default RemoveCustomField;
