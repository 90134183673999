import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import FilterDrawer from '../common/FilterDrawer';
import CompCard from '../common/CompCard';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import memoize from 'memoize-one';
import ReportsModal from '../common/ReportsModal';
import './viewData.css';
import { GMap } from '../common/GMap';

const commercialSubcategories = [
	'Apartment',
	'Retail',
	'Industrial',
	'Land',
	'Office',
	'Shopping',
	'Self Storage',
	'Restaurant',
	'Medical',
];
const agSubcategories = ['Dairy', 'Open Ground', 'Permanent Plantings'];
const categories = ['Commercial', 'Agriculture'];

const AntSwitch = withStyles((theme) =>
	createStyles({
		root: {
			width: 28,
			height: 16,
			padding: 0,
			display: 'flex',
		},
		switchBase: {
			padding: 2,
			color: theme.palette.grey[500],
			'&$checked': {
				transform: 'translateX(12px)',
				color: theme.palette.common.white,
				'& + $track': {
					opacity: 1,
					backgroundColor: theme.palette.grey[500],
					borderColor: theme.palette.grey[500],
				},
			},
		},
		thumb: {
			width: 12,
			height: 12,
			boxShadow: 'none',
		},
		track: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: theme.palette.common.white,
		},
		checked: {},
	})
)(Switch);

const getSize = (comp) => {
	const sc = comp.subcategory;
	let acres = false;

	switch (sc) {
		case 'dairy':
			acres = ((comp.DairySiteInfo || {}).values || {}).totalAcres || '';
			break;
		case 'industrial':
			acres =
				((comp.IndustrialSiteInfo || {}).values || {}).siteAreaAcres || '';
			break;
		case 'openGround':
			acres = ((comp.OpenSiteInfo || {}).values || {}).siteAcres || '';
			break;
		case 'permanentPlantings':
			acres = ((comp.PlantingsSiteInfo || {}).values || {}).siteAcres || '';
			break;
		case 'land':
			acres = ((comp.LandSiteInfo || {}).values || {}).siteAreaAcres || '';
			break;
		default:
			acres = ((comp.SiteInfo || {}).values || {}).siteAreaAcres || '';
	}
	return Number(acres);
};

const leaseSubCats = [
	'Medical',
	'Shopping',
	'Retail',
	'Industrial',
	'Office',
	'Restaurant',
	'all',
];

const getQueryVariable = (variable, q) => {
	var query = q.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
	return false;
};

export default class ViewData extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dataLoading: false,
			center: null,
			useSearchParams: true,
			reportPageOpen: false,
			showReportPage: false,
			isLease: false,
			compView: true,
			showClearFiltersBtn: false,
			showLeaseSwitch: false,
			filters: {
				category: props.location.category || 'Commercial',
				subcategory: props.location.subcategory || 'Medical',
			},
		};
	}

	async componentDidMount() {
		let { category, subcategory } = this.props.location || {};
		let addr = '';

		if (this.state.useSearchParams) {
			let q = window.location.search;
			category = getQueryVariable('cat', q) || category || '';
			subcategory = getQueryVariable('sc', q) || subcategory || '';
			addr = getQueryVariable('address', q) || '';
			this.setState({ useSearchParams: false });
		}
		const addrFound = addr.length > 0;
		const address = addrFound ? [addr] : [];

		if (addrFound) {
			this.setState({ showClearFiltersBtn: true });
		}

		if (!this.props.allRecords) {
			try {
				this.setState({ dataLoading: true });
				await this.props.getRecords();
				let records = this.props.allRecords;
				this.setState({ records, dataLoading: false });
			} catch (error) {
				console.log(`error:: ${error}`);
			}
		}
		// if sc is present we should expect cat to be there as well
		if (subcategory.length > 0) {
			const filters = { ...this.state.filters };
			const newFilters = {
				...filters,
				subcategory,
				category,
				address,
			};
			this.setFilters(newFilters);
			const showLeaseSwitch =
				category.toLowerCase() === 'commercial' &&
				leaseSubCats.includes(subcategory);
			this.setState({ showLeaseSwitch });
		} else {
			const filters = { ...this.state.filters };
			if (filters.subcategory) {
				const showLeaseSwitch =
					category.toLowerCase() === 'commercial' &&
					leaseSubCats.includes(filters.subcategory);
				this.setState({ showLeaseSwitch });
			}
			this.setFilters(filters);
		}
	}

	goHome = () => {
		const path = `/`;
		this.props.history.push(path);
	};

	clearFilters = () => {
		const filters = { ...this.state.filters };
		const newFilters = {
			subcategory: filters.subcategory,
			category: filters.category,
		};
		this.setFilters(newFilters);
	};

	handleSubcategoryChange = async (event) => {
		const category = (this.state.filters || {}).category || '';
		const subCat = event.target.value;
		const filters = { ...this.state.filters };
		const showLeaseSwitch =
			category.toLowerCase() === 'commercial' && leaseSubCats.includes(subCat);
		const newFilters = {
			...filters,
			subcategory: subCat,
		};
		this.setFilters(newFilters);
		this.setState({ showLeaseSwitch });
		this.resetCompType();
	};

	handleCategoryChange = (event) => {
		const filters = { ...this.state.filters };
		const cat = event.target.value;
		const subCat = filters.subcategory || '';
		const showLeaseSwitch =
			cat.toLowerCase() === 'commercial' && leaseSubCats.includes(subCat);
		if (event.target.value === filters.category) {
			return true;
		}
		const newFilters = {
			...filters,
			category: event.target.value,
			subcategory: 'all',
		};
		this.setFilters(newFilters);
		this.setState({ showLeaseSwitch });
		this.resetCompType();
	};

	showReportPage = () => {
		this.setState({ showReportPage: true });
	};

	handleClickOpen = () => {
		const compsAdded = this.props.reportComps.length > 0;
		if (compsAdded) {
			this.setState({ reportPageOpen: true });
		}
	};

	handleClose = () => {
		this.setState({ reportPageOpen: false });
	};

	handleTypeAheadSearchChange = async (searchValues = []) => {
		const filters = { ...this.state.filters };
		const newFilters = {
			...filters,
			searchValues,
		};
		this.setFilters(newFilters);
	};

	getFilteredRecords = memoize((filters, allRecords) => {
		const toNum = (str = '') => {
			return Number(str.replace('$', '').replace(/,/g, ''));
		};
		let records = allRecords || this.props.allRecords;

		if (filters.isLease) {
			records = records.filter((r) => r.isLease);
		} else {
			records = records.filter((r) => !r.isLease);
		}
		if (filters.MinSqft || filters.MaxSqft) {
			const minSqft = filters.MinSqft || '0';
			const maxSqft = filters.MaxSqft || '999999999';
			records = records.filter((r) => {
				let data = r.SiteInfo || r.LandSiteInfo || r.IndustrialSiteInfo || {};
				let sqft = toNum((data.values || {}).siteAreaSf);
				if (!Boolean(sqft)) {
					return false;
				}
				return sqft >= toNum(minSqft) && sqft <= toNum(maxSqft);
			});
		}
		if (filters.MinAcres || filters.MaxAcres) {
			const minAcres = filters.MinAcres || '0';
			const maxAcres = filters.MaxAcres || '999999999';
			records = records.filter((r) => {
				let acres = getSize(r);
				if (!Boolean(acres)) {
					return false;
				}
				return acres >= toNum(minAcres) && acres <= toNum(maxAcres);
			});
		}
		if (filters.MinPrice || filters.MaxPrice) {
			const min = filters.MinPrice || '0';
			const max = filters.MaxPrice || '999999999';
			records = records.filter((r) => {
				let data =
					r.TransactionalData ||
					r.LandTransactionalData ||
					r.PlantingsTransactionalData ||
					r.OpenTransactionalData ||
					r.DairyTransactionalData ||
					{};
				const price = toNum((data.values || {}).salePrice);
				if (!price) {
					return false;
				}
				return price >= toNum(min) && price <= toNum(max);
			});
		}
		if (filters.subcategory) {
			records = records.filter((r) => {
				if (filters.subcategory === 'all') {
					const category = (filters.category || '').toLowerCase();
					return r.category === category;
				}
				let key = this.props.getDbKey(filters.subcategory);
				const subCat = (key.charAt(0) || '').toLowerCase() + key.slice(1);
				const match = r.subcategory === this.props.getDbKey(subCat);
				if (filters.includeOG) {
					return match || r.subcategory === 'openGround';
				}
				return match;
			});
		}
		if (filters.searchValues && filters.searchValues.length > 0) {
			records = filters.searchValues
				.map((s) => {
					if (s.type === 'commodity') {
						return records.filter((r) => {
							let commodity = ((r.Plantings || {}).values || [])
								.map((p) => p.type)
								.join(' ');
							return commodity.includes(s.value);
						});
					} else {
						return records.filter((record) => {
							const val = ((record.PropertyInfo || {}).values || {})[s.type];
							return val === s.value;
						});
					}
				})
				.flat();
		}
		if (filters.apn && filters.apn.length > 0) {
			let apns = filters.apn;
			records = records.filter((r) => {
				let apn = ((r.PropertyInfo || {}).values || {}).apn || 'null';
				return apns.includes(apn);
			});
		}
		if (filters.city && filters.city.length > 0) {
			let cities = filters.city;
			records = records.filter((r) => {
				let city = ((r.PropertyInfo || {}).values || {}).city || 'null';
				return cities.includes(city);
			});
		}
		if (filters.county && filters.county.length > 0) {
			let counties = filters.county;
			records = records.filter((r) => {
				let county = ((r.PropertyInfo || {}).values || {}).county || 'null';
				return counties.includes(county);
			});
		}
		if (filters.address && filters.address.length > 0) {
			let addresses = filters.address;
			records = records.filter((r) => {
				let address = ((r.PropertyInfo || {}).values || {}).address || 'null';
				return addresses.includes(address);
			});
		}
		if (filters.startRecordingDate) {
			let startDateFilter = new Date(filters.startRecordingDate);
			records = records.filter((r) => {
				let startTData = this.getTData(r);
				let startRDate = (startTData || {}).recordingDate || '';
				if (startRDate === '') {
					return true;
				}
				let startRDateObj = new Date(startRDate);
				return startRDateObj >= startDateFilter;
			});
		}
		if (filters.endRecordingDate) {
			let endDateFilter = new Date(filters.endRecordingDate);
			records = records.filter((r) => {
				let endTData = this.getTData(r);
				let endRDate = (endTData || {}).recordingDate || '';
				if (endRDate === '') {
					return true;
				}
				let endRDateObj = new Date(endRDate);
				return endRDateObj <= endDateFilter;
			});
		}
		if (filters.plantings && filters.plantings.length > 0) {
			let plantings = filters.plantings;
			records = records.filter((r) => {
				const allPlantings = ((r.Plantings || {}).values || []).map(
					(p) => p.type
				);
				const plFound =
					allPlantings.filter((p) => plantings.includes(p)).length > 0;
				if (filters.includeOG) {
					return plFound || r.subcategory === 'openGround';
				}
				return plFound;
			});
		}
		if (filters.water && filters.water.length > 0) {
			let waters = filters.water;
			records = records.filter((r) => {
				let water =
					(
						(r.DairySiteInfo || r.OpenSiteInfo || r.PlantingsSiteInfo || {})
							.values || {}
					).surfaceWater || '';
				return waters.includes(water);
			});
		}

		return records;
	});

	setFilters = (filters) => {
		this.setState({ filters });
	};

	setIsLeaseFilter = () => {
		const isLease = this.state.isLease;
		const filters = this.state.filters;
		const newFilters = {
			...filters,
			isLease,
		};
		this.setFilters(newFilters);
	};

	getTData = (record) => {
		const tData =
			record.TransactionalData ||
			record.LandTransactionalData ||
			record.PlantingsTransactionalData ||
			record.OpenTransactionalData ||
			record.DairyTransactionalData ||
			{};
		return tData.values;
	};

	sortRecords = (records) => {
		const sortedRecords = records.sort((a, b) => {
			let dataA = this.getTData(a);
			let dataB = this.getTData(b);
			let dateA = new Date((dataA || {}).recordingDate || ''),
				dateB = new Date((dataB || {}).recordingDate || '');
			return dateB - dateA;
		});
		return sortedRecords;
	};

	resetCompType = async () => {
		await this.setState({ isLease: false });
		this.setIsLeaseFilter();
	};

	toggleCompType = async () => {
		const newState = !this.state.isLease;
		await this.setState({ isLease: newState });
		this.setIsLeaseFilter();
	};

	toggleCompView = async () => {
		const newState = !this.state.compView;
		await this.setState({ compView: newState });
	};

	clearAllComps = () => {
		this.props.removeFromReport({}, true);
	};

	getMarkerData = (comps) => {
		const tdKeys = {
			dairy: 'DairyTransactionalData',
			land: 'LandTransactionalData',
			openGround: 'OpenTransactionalData',
			permanentPlantings: 'PlantingsTransactionalData',
		};
		const useKeys = {
			dairy: 'DairySiteInfo',
			openGround: 'OpenSiteInfo',
			permanentPlantings: 'PlantingsSiteInfo',
		};
		let data = [];
		for (let i = 0; i < comps.length; i++) {
			let tdKey = '';
			if (comps[i].category === 'commercial') {
				tdKey =
					comps[i].subcategory === 'land'
						? 'LandTransactionalData'
						: 'TransactionalData';
			} else {
				tdKey = tdKeys[comps[i].subcategory];
			}
			let use = false;
			let comp = comps[i];
			if (
				comp.subcategory === 'permanentPlantings' ||
				comp.subcategory === 'openGround'
			) {
				use = (comp[useKeys[comp.subcategory]].values || {}).use || '';
			}
			const { latitude, longitude } =
				((comps[i] || {}).PropertyInfo || {}).values || {};

			if (typeof latitude !== 'undefined' && typeof longitude !== 'undefined') {
				const index = i + 1;
				const rd = ((comp[tdKey] || {}).values || {}).recordingDate || false;
				const markerData = {
					latitude,
					longitude,
					index,
					comp: comps[i],
					use,
					surfaceWater:
						((comp[useKeys[comp.subcategory]] || {}).values || {})
							.surfaceWater || false,
					pricePerAcre:
						((comp[tdKey] || {}).values || {}).pricePerAcre || false,
					recordingDate:
						((comp[tdKey] || {}).values || {}).recordingDate || false,
				};
				data.push(markerData);
			}
		}
		return data;
	};

	render() {
		const { filters, reportPageOpen } = this.state;
		const category = this.state.filters.category;
		const {
			allRecords,
			reportComps,
			addToReport,
			imageURL,
			removeFromReport,
			updateImageURL,
		} = this.props;
		let initialRecords = allRecords
			? this.getFilteredRecords(filters, allRecords)
			: [];

		const records = this.sortRecords(initialRecords);
		const recordsFound = records && records.length > 0;
		let markers = [];
		let reportCompMarkers = [];
		const compsAdded = reportComps.length > 0;
		if (recordsFound) {
			markers = this.getMarkerData(records);
		}
		if (compsAdded) {
			reportCompMarkers = this.getMarkerData(reportComps);
		}
		const ids = records.map((record) => record.id);
		const reportIds = reportComps.map((record) => record.id);
		const isComm = category === 'Commercial' || category === 'commercial';
		const subcategories = isComm ? commercialSubcategories : agSubcategories;
		const initialFilterRecords = allRecords || [];

		return (
			<div className="ViewData">
				<ReportsModal
					handleClose={this.handleClose}
					reportComps={reportComps}
					imageURL={imageURL}
					updateImageURL={updateImageURL}
					open={reportPageOpen}
				/>
				<div className="ViewData-filterRow">
					<div className="ViewData-searchAndFilter">
						<FormControl
							className="ViewData-filterDropdown"
							variant="outlined"
							style={{ minWidth: 120 }}
						>
							<InputLabel htmlFor="category-select-label">Category</InputLabel>
							<Select
								defaultValue={this.state.filters.category}
								value={this.state.filters.category}
								onChange={this.handleCategoryChange}
								labelId="category-select-label"
								id="category-select"
								label="Type"
							>
								{categories.map((opt, i) => (
									<option
										key={`option-${i}`}
										value={opt}
										name={opt}
										className={`${
											this.state.filters.category === opt
												? 'ViewData-option ViewData-optionSelected'
												: 'ViewData-option'
										}`}
									>
										{opt}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							className="ViewData-filterDropdown"
							variant="outlined"
							style={{ minWidth: 120, marginLeft: '10px' }}
						>
							<InputLabel htmlFor="subcategory-select-label">
								Subcategory
							</InputLabel>
							<Select
								defaultValue={this.state.filters.subcategory}
								value={this.state.filters.subcategory}
								onChange={this.handleSubcategoryChange}
								labelId="subcategory-select-label"
								id="subcategory-select"
								label="Type"
							>
								<option
									key="option-all"
									value="all"
									name="all"
									className={`${
										this.state.filters.subcategory === 'all'
											? 'ViewData-option ViewData-optionSelected'
											: 'ViewData-option'
									}`}
								>
									All
								</option>
								{subcategories.map((opt, i) => (
									<option
										key={`option-${i}`}
										value={opt}
										name={opt}
										className={`${
											this.state.filters.subcategory === opt
												? 'ViewData-option ViewData-optionSelected'
												: 'ViewData-option'
										}`}
									>
										{opt}
									</option>
								))}
							</Select>
						</FormControl>

						<FilterDrawer
							records={initialFilterRecords}
							filters={this.state.filters}
							setFilters={this.setFilters}
							getDbKey={this.props.getDbKey}
						/>

						<Button
							className="doneViewingNewComp"
							variant="outlined"
							onClick={this.clearFilters}
						>
							Clear Filters
						</Button>

						<Typography component="div" className="compViewSwitch">
							<Grid component="label" container alignItems="center" spacing={1}>
								<Grid
									className={
										this.state.compView ? 'switchBtn active' : 'switchBtn'
									}
									item
								>
									Comp View
								</Grid>
								<Grid item>
									<AntSwitch
										checked={!this.state.compView}
										onChange={this.toggleCompView}
										name="compView"
									/>
								</Grid>
								<Grid
									className={
										this.state.compView ? 'switchBtn' : 'switchBtn active'
									}
									item
								>
									Map View
								</Grid>
							</Grid>
						</Typography>

						{this.state.showLeaseSwitch && <div className="switchSeparator" />}
						{!this.state.showLeaseSwitch && (
							<div className="reportsBtnSeparator" />
						)}

						{this.state.showLeaseSwitch && (
							<Typography component="div" className="viewDataLeaseSwitch">
								<Grid
									component="label"
									container
									alignItems="center"
									spacing={1}
								>
									<Grid
										className={
											this.state.isLease ? 'switchBtn' : 'switchBtn active'
										}
										item
									>
										Sales
									</Grid>
									<Grid item>
										<AntSwitch
											checked={this.state.isLease}
											onChange={this.toggleCompType}
											name="isLease"
										/>
									</Grid>
									<Grid
										className={
											this.state.isLease ? 'switchBtn active' : 'switchBtn'
										}
										item
									>
										Lease
									</Grid>
								</Grid>
							</Typography>
						)}
					</div>
					<div className="viewDataRightSide">
						{compsAdded && (
							<Button
								color="primary"
								className="clearCompsBtn"
								onClick={this.clearAllComps}
							>
								Clear All
							</Button>
						)}
						<div
							onClick={this.handleClickOpen}
							className={`${
								compsAdded
									? 'ViewData-ReportComps ViewData-ReportCompsAdded'
									: 'ViewData-ReportComps'
							}`}
						>
							<LibraryBooksOutlinedIcon fontSize="large" />
						</div>
					</div>
				</div>

				{!this.state.dataLoading && !recordsFound && (
					<div className="ViewData-noRecordsMsg">
						<h4>No records found.</h4>
					</div>
				)}
				{markers.length > 0 && !this.state.compView && (
					<GMap
						compsAdded={compsAdded}
						reportCompMarkers={reportCompMarkers}
						markers={markers}
					/>
				)}
				<div
					className={`${
						allRecords ? 'ViewData-spinner--hidden' : ''
					} ViewData-spinner`}
				>
					<Spinner animation="border" variant="secondary" />
				</div>
				{this.state.compView && (
					<div className="ViewData-contents">
						<div className="ViewData-comps">
							{recordsFound &&
								records.map((comp, i) => (
									<CompCard
										isInReport={reportIds.includes(ids[i])}
										addToReport={addToReport}
										removeFromReport={removeFromReport}
										key={`comp-${i}`}
										comp={comp}
									/>
								))}
						</div>
					</div>
				)}
			</div>
		);
	}
}
