import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ConfirmDelete = ({ buttonProps, onConfirm }) => {
	return (
		<PopupState variant="popover" popupId="attachmentDeleteBtn">
			{(popupState) => (
				<div>
					<IconButton {...buttonProps} {...bindTrigger(popupState)}>
						<DeleteIcon />
					</IconButton>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'center',
							horizontal: 'right',
						}}
					>
						<ButtonGroup fullWidth variant="outlined">
							<Tooltip placement="top" title="Delete attachment">
								<Button onClick={onConfirm}>
									<CheckIcon color="error" />
								</Button>
							</Tooltip>
							<Tooltip placement="top" title="Keep attachment">
								<Button onClick={popupState.close}>
									<ClearIcon color="action" />
								</Button>
							</Tooltip>
						</ButtonGroup>
					</Popover>
				</div>
			)}
		</PopupState>
	);
};

export default ConfirmDelete;
