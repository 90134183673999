import Box from '@material-ui/core/Box';
import Context from './Context';
import Field from './Field';
import React from 'react';
import RemoveCustomField from './RemoveCustomField';
import Typography from '@material-ui/core/Typography';
import Wrapper from './Wrapper';

const Col = ({ index, label, showRemoveCustomField, values }) => {
	const { actions, fields } = React.useContext(Context);
	const setValue = React.useCallback(
		({ key, value }) =>
			actions.setEntryValues({ index, values: { [key]: value } }),
		[actions, index]
	);

	return (
		<Box>
			{fields.map(({ isCustom, key, label, type }) => (
				<Box key={key} py={0.25}>
					<Field
						addon={
							isCustom &&
							showRemoveCustomField && <RemoveCustomField fieldKey={key} />
						}
						fieldKey={key}
						label={label}
						setValue={setValue}
						type={type}
						value={values[key]}
					/>
				</Box>
			))}
		</Box>
	);
};

const FixedCol = ({ values }) => {
	const { actions, colLabels } = React.useContext(Context);

	React.useEffect(() => {
		// initialize the values if undefined
		const existing = values || [];

		for (let i = existing.length; i < colLabels.length; i++) {
			actions.addEntry({ label: colLabels[i] });
		}
	}, [actions, colLabels, values]);

	return (
		<Wrapper>
			<Box display="flex" flexWrap="none" p={1}>
				{values &&
					colLabels.map((label, index) => (
						<Box flexGrow={1} key={index} p={1}>
							<Typography gutterBottom variant="subtitle1">
								{label}
							</Typography>
							<Col
								index={index}
								showRemoveCustomField={index === colLabels.length - 1}
								values={values[index]}
							/>
						</Box>
					))}
			</Box>
		</Wrapper>
	);
};

export default FixedCol;
