import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import TextField from '@material-ui/core/TextField';
import './FilterInput.css';
import Button from '@material-ui/core/Button';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default class FilterInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			startFocused: false,
			endFocused: false,
			startRecordingDate: moment(),
			endRecordingDate: moment(),
		};
	}

	render() {
		const options = this.props.options;
		const title = this.props.title;
		const inputKey = this.props.inputKey;
		const type = this.props.type || 'text';
		const id = this.props.id || 'filterInput';
		const sd = this.props.filters.startRecordingDate;
		const ed = this.props.filters.endRecordingDate;
		const { includeOG } = this.props.filters;

		const handleChange = (el, choices) => {
			const key = inputKey;
			const values = choices.map((c) => c.value);
			this.props.handler(key, values);
		};
		const handleDateChange = (key, date) => {
			this.setState({ [key]: date });
			const dateObj = new Date(date);
			this.props.handler(key, dateObj);
		};

		const handleOGChange = () => {
			this.props.handler('includeOG', !includeOG);
		};

		let defaultValue = [];
		if (type === 'text') {
			const filterValues = this.props.filters[inputKey];
			if (filterValues) {
				for (var i = filterValues.length - 1; i >= 0; i--) {
					defaultValue.push({ value: filterValues[i] });
				}
			}
		}

		return (
			<ListItem className="FilterDrawerItem FilterDrawer-AutoInput">
				<div className="filterTitle">{title}</div>
				<div className="FilterDrawer-FilterInput">
					{inputKey === 'includeOG' && (
						<div className="FilterInput">
							<Button
								size="small"
								color="primary"
								onClick={handleOGChange}
								style={{ outline: 'none' }}
							>
								{includeOG ? (
									<CheckBoxIcon
										style={{
											fontSize: '33px',
											color: 'dodgerblue',
											outline: 'none',
										}}
									/>
								) : (
									<CheckBoxOutlineBlankIcon
										style={{
											fontSize: '33px',
											color: '#abacad',
											outline: 'none',
										}}
									/>
								)}
								Include Open Ground
							</Button>
						</div>
					)}
					{inputKey !== 'includeOG' && type === 'text' && (
						<div className="FilterInput">
							<Autocomplete
								multiple
								options={options}
								value={defaultValue}
								onChange={handleChange}
								getOptionLabel={(option) => option.value}
								style={{ width: 300 }}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" />
								)}
							/>
						</div>
					)}
					{type === 'date' && (
						<div className="FilterInput">
							<SingleDatePicker
								numberOfMonths={1}
								date={(sd && moment(sd)) || this.state.startRecordingDate}
								focused={this.state.startFocused}
								onFocusChange={({ focused }) =>
									this.setState({ startFocused: focused })
								}
								onDateChange={(date) =>
									handleDateChange('startRecordingDate', date)
								}
								isOutsideRange={() => false}
								id={`${id}-start`}
							/>
							<div className="dateDivider">to</div>
							<SingleDatePicker
								numberOfMonths={1}
								date={(ed && moment(ed)) || this.state.endRecordingDate}
								focused={this.state.endFocused}
								onFocusChange={({ focused }) =>
									this.setState({ endFocused: focused })
								}
								onDateChange={(date) =>
									handleDateChange('endRecordingDate', date)
								}
								isOutsideRange={() => false}
								id={`${id}-end`}
							/>
						</div>
					)}
				</div>
			</ListItem>
		);
	}
}
