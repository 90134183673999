import API from '@aws-amplify/api';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Section from './Section';
import UserContext from '../../UserContext';
import RecordsContext from '../../RecordsContext';
import { recordTypes } from '@hower/shared';
import Cookies from 'universal-cookie';
import uuid from 'uuid4';

const cookies = new Cookies();

const initReducer = ({ record, user }) => ({
	data: {
		id: uuid(),
		userIdCreatedBy: (user || {}).sub,
		...record,
		userIdLastUpdatedBy: (user || {}).sub,
	},
});

const updateFormCookie = (data) => {
	const formData = JSON.stringify(data);
	cookies.set('formData', formData, { path: '/' });
};

const hasValues = (data) => {
	const { category, subcategory } = data;
	delete data['category'];
	delete data['id'];
	delete data['subcategory'];
	if (Object.keys(data).length === 0) {
		return false;
	}
	const vals = Object.values(data)
		.map((val) => {
			if (typeof val === 'object') {
				if (Array.isArray(val.values)) {
					return (
						val.values.filter((o) => {
							delete o['label'];
							return (
								Object.values(o).filter(
									(val) => val && val.toString().length > 0
								).length > 0
							);
						}).length > 0
					);
				} else if (val.values) {
					return (
						Object.values(val.values).filter(
							(val) => val && val.toString().length > 0
						).length > 0
					);
				}
			}
			return false;
		})
		.filter((bool) => bool);
	data['category'] = category;
	data['subcategory'] = subcategory;
	return vals.length > 0;
};

const reducer = (state, action) => {
	let path = ((window || {}).location || {}).pathname || '/';
	switch (action.type) {
		case 'reset':
			return initReducer(action.payload);
		case 'updateSection':
			const newState = {
				...state,
				data: {
					...state.data,
					[action.sectionKey]: action.payload,
				},
			};
			if (hasValues(state.data) && path !== '/addData') {
				updateFormCookie(newState);
			}
			return newState;
		default:
			throw new Error('unsupported action');
	}
};

const removeEmptyStringElements = (obj) => {
	for (var prop in obj) {
		if (typeof obj[prop] === 'object') {
			// dive deeper in
			removeEmptyStringElements(obj[prop]);
		} else if (obj[prop] === '') {
			// delete elements that are empty strings
			delete obj[prop];
		}
	}
};

const testRecord = {
	category: 'commercial',
	subcategory: 'apartment',
};

const SectionWrapper = React.memo(
	({ isLease, dispatch, initialState, section, formState }) => {
		const onChange = React.useCallback(
			(payload) =>
				dispatch({ sectionKey: section.key, payload, type: 'updateSection' }),
			[dispatch, section.key]
		);

		return (
			<Section
				formState={formState}
				isLease={isLease}
				initialState={initialState}
				onChange={onChange}
				section={section}
			/>
		);
	}
);

const SUB_CATS = {
	dairy: 'Dairy',
	openGround: 'Open Ground',
	permanentPlantings: 'Permanent Plantings',
	apartment: 'Apartment',
	retail: 'Retail',
	industrial: 'Industrial',
	land: 'Land',
	office: 'Office',
	shopping: 'Shopping',
	selfStorage: 'Self Storage',
	restaurant: 'Restaurant',
	medical: 'Medical',
};

const saveNow = async (record, updateRecord, isLease) => {
	removeEmptyStringElements(record);
	const date = new Date();
	const dateString = date.toISOString();
	record['updatedDate'] = dateString;
	const isLeaseVal = isLease ? true : false;
	record['isLease'] = isLeaseVal;
	try {
		const res = await API.post('howerAPI', '/records', {
			body: record,
		});
		updateFormCookie({});
		await updateRecord(record);
		return res;
	} catch (error) {
		console.log('error in saveNow:', error);
	}
};

const save = async (record, updateRecord, isLease) => {
	removeEmptyStringElements(record);
	const date = new Date();
	const dateString = date.toISOString();
	record['updatedDate'] = dateString;
	const isLeaseVal = isLease ? true : false;
	record['isLease'] = isLeaseVal;

	try {
		await API.post('howerAPI', '/records', {
			body: record,
		});
		updateFormCookie({});
		await updateRecord(record);
		let path = ((window || {}).location || {}).pathname || '/';
		let newPath = '';
		let sc = SUB_CATS[record.subcategory];
		let cat = record.category;
		let capCat = cat.charAt(0).toUpperCase() + cat.slice(1);
		if (path === '/addData') {
			let addr = ((record.PropertyInfo || {}).values || {}).address || '';
			newPath = `/viewData?sc=${sc}&cat=${capCat}&address=${addr}`;
			window.location.reload(false);
			window.location = newPath;
		} else {
			window.location = `/viewData?sc=${sc}&cat=${capCat}`;
		}
	} catch (error) {
		console.log('error in save inside RecordForm:', error);
	}
};

const deleteRecord = async (record, updateRecord) => {
	removeEmptyStringElements(record);
	const date = new Date();
	const dateString = date.toISOString();
	record['updatedDate'] = dateString;
	record['deleted'] = true;
	try {
		await API.post('howerAPI', '/records', {
			body: record,
		});
		updateFormCookie({});
		await updateRecord(record);
		let sc = SUB_CATS[record.subcategory];
		let cat = record.category;
		let capCat = cat.charAt(0).toUpperCase() + cat.slice(1);
		window.location.reload(false);
		window.location = `/viewData?sc=${sc}&cat=${capCat}`;
	} catch (error) {
		console.log('error in updateRecord:', error);
		// show server error message
	}
};

const RecordFormContext = React.createContext();

export const withRecordForm = (WrappedComponent) => (props) => {
	const stateRef = React.createRef();
	const updateRecord = React.useContext(RecordsContext);
	return (
		<RecordFormContext.Provider value={stateRef}>
			<WrappedComponent
				{...props}
				Form={RecordForm}
				handleDelete={() => deleteRecord(stateRef.current.data, updateRecord)}
				handleSave={() =>
					save(stateRef.current.data, updateRecord, stateRef.current.isLease)
				}
				handleSaveNow={() =>
					saveNow(stateRef.current.data, updateRecord, stateRef.current.isLease)
				}
			/>
		</RecordFormContext.Provider>
	);
};

const widths = [4, 4, 4, 12, 8, 4, 12, 12, 12];

const RecordForm = ({ isLease, record = testRecord }) => {
	let path = ((window || {}).location || {}).pathname || '/';
	const form = cookies.get('formData');
	const formData = (form || {}).data;
	if (
		path !== '/addData' &&
		Object.keys(record).length < 3 &&
		formData &&
		record.subcategory === formData.subcategory
	) {
		record = formData;
	}
	const user = React.useContext(UserContext);
	const [state, dispatch] = React.useReducer(
		reducer,
		{ record, user },
		initReducer
	);
	const stateRef = React.useContext(RecordFormContext);
	React.useEffect(() => {
		stateRef.current = {
			...state,
			isLease,
		};
	}, [state, stateRef]);
	const sections =
		recordTypes[record.category].subcategories[record.subcategory].sections;
	const formState = state;

	return (
		<Container maxWidth="xl">
			<Grid container spacing={2}>
				{sections.map((section, index) => {
					if (isLease) {
						if (!section.hideOnLease) {
							return (
								<Grid
									item
									key={section.key}
									lg={section.width || widths[index]}
									xs={12}
								>
									<SectionWrapper
										formState={formState}
										isLease={isLease}
										dispatch={dispatch}
										initialState={record[section.key]}
										key={`${state.timestamp}${section.key}`}
										section={section}
									/>
								</Grid>
							);
						}
					} else {
						return (
							<Grid
								item
								key={section.key}
								lg={section.width || widths[index]}
								xs={12}
							>
								<SectionWrapper
									formState={formState}
									dispatch={dispatch}
									initialState={record[section.key]}
									key={`${state.timestamp}${section.key}`}
									section={section}
								/>
							</Grid>
						);
					}
				})}
			</Grid>
		</Container>
	);
};

export default RecordForm;
