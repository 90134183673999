import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
	},
}))(MuiDialogContent);

const getS3Link = async (attachment) => {
	try {
		const link = await Storage.get(attachment.s3Key);
		return link;
	} catch (error) {
		console.log('Error in getting S3 link:', error);
	}
};

const getLinks = async (attachments) => {
	if (!attachments) {
		return null;
	}
	return Promise.all(attachments.map((key) => getS3Link(key)));
};

export default class AttachmentsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			links: [],
		};
	}

	handleClickOpen = async () => {
		const links = await getLinks(this.props.attachments);
		this.setState({ links, open: true });
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const links = this.state.links;
		if (!this.props.attachments) {
			return null;
		}

		return (
			<div>
				<Button
					onClick={this.handleClickOpen}
					size="small"
					variant="outlined"
					color="primary"
					className="CompCard-attachmentsBtn"
				>
					Attachments
				</Button>
				<Dialog
					onClose={this.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.state.open}
				>
					<DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
						Record Attachments
					</DialogTitle>
					<DialogContent dividers>
						{links.map((link, i) => (
							<Button
								key={`link-${i}`}
								href={link}
								target="_blank"
								color="primary"
							>
								{(this.props.attachments[i] || {}).name}
							</Button>
						))}
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
