import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Storage } from 'aws-amplify';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Spinner } from 'react-bootstrap';
import API from '@aws-amplify/api';
import './reportsModal.css';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

class ReportsModal extends React.Component {
	state = {
		loading: false,
		excelLoading: false,
		cbChecked: false,
	};

	getCompsData = () =>
		this.props.reportComps.map((comp) => ({
			id: comp.id,
			attachments: comp.Attachments,
		}));

	updateCheckbox = (e) => {
		const bool = e.target.checked;
		this.setState({ cbChecked: bool });
	};

	postReports = async () => {
		this.setState({ loading: true });
		const response = await API.post('reportsAPI', '/reports', {
			body: {
				comps: this.getCompsData(),
				hideCalculations: this.state.cbChecked,
			},
		});
		const pdfKey = response.pdfKey;
		const imageURL = await Storage.get(`reports/${pdfKey}`);
		this.props.updateImageURL(imageURL);
		this.setState({ loading: false });
	};

	postExcel = async () => {
		this.setState({ loading: true });
		const response = await API.post('excelAPI', '/excel', {
			body: {
				comps: this.getCompsData(),
			},
		});
		const excelKey = response.excelKey;
		const excelURL = await Storage.get(`excel/${excelKey}`);
		this.setState({ excelURL, loading: false });
	};

	render() {
		const showCB =
			(this.props.reportComps || []).filter(
				(c) => c.subcategory === 'permanentPlantings'
			).length > 0;
		const imageURL = this.props.imageURL;
		const { cbChecked, excelURL } = this.state;
		const disableBtn = Boolean(this.state.loading || imageURL);
		const disableExcelBtn = Boolean(this.state.loading || excelURL);

		return (
			<div>
				<Dialog
					onClose={this.props.handleClose}
					aria-labelledby="customized-dialog-title"
					open={this.props.open}
				>
					<DialogTitle
						id="customized-dialog-title"
						onClose={this.props.handleClose}
					>
						Downloads
					</DialogTitle>
					<DialogActions>
						<div>
							<Button
								className="excelBtn"
								variant="contained"
								onClick={this.postExcel}
								disabled={disableExcelBtn}
								disableElevation={disableExcelBtn}
								disableRipple={disableExcelBtn}
								disableFocusRipple={disableExcelBtn}
							>
								Generate Excel Sheet
							</Button>
							<Button
								className="reportBtn"
								variant="contained"
								onClick={this.postReports}
								disabled={disableBtn}
								disableElevation={disableBtn}
								disableRipple={disableBtn}
								disableFocusRipple={disableBtn}
							>
								Generate Report
							</Button>
						</div>
						<div className="reportModalWrapper">
							{this.state.loading && (
								<Spinner animation="border" variant="secondary" />
							)}
							{excelURL && (
								<a target="_blank" rel="noopener noreferrer" href={excelURL}>
									Download Excel File
								</a>
							)}
							{imageURL && (
								<a target="_blank" rel="noopener noreferrer" href={imageURL}>
									Download PDF Report
								</a>
							)}
						</div>
						{showCB && (
							<div className="reportModalWrapper">
								<FormControlLabel
									control={
										<Checkbox
											checked={cbChecked}
											onChange={this.updateCheckbox}
											color="primary"
										/>
									}
									label="Hide Calculations for IRR/OAR/APP/DEP"
								/>
							</div>
						)}
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default ReportsModal;
