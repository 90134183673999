import React from 'react';
import CustomSignIn from './auth/CustomSignIn';
import CustomForgotPassword from './auth/CustomForgotPassword';
import AddData from './components/AddData';
import Page from './components/Page';
import ViewData from './components/ViewData';
import Home from './components/Home';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
	ConfirmSignIn,
	RequireNewPassword,
	VerifyContact,
	Authenticator,
} from 'aws-amplify-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Modal from 'react-modal';
import './App.css';
import Amplify, { Auth } from 'aws-amplify';
import UserContext from './UserContext';
import RecordsContext from './RecordsContext';
import API from '@aws-amplify/api';
import config from './aws-exports';
import 'bootstrap/dist/css/bootstrap.min.css';

// import Geocode from "react-geocode";
// Geocode.setApiKey("AIzaSyCMVKYc5GMdmW4T0rrLg7dxxKI40FGztiU");

Amplify.configure(config);

const getDbKey = (label = '') =>
	label
		.replace('$', 'Price')
		.replace('#', 'Num')
		.replace('/', '_')
		.replace('%', 'Percent')
		.replace(/\W/g, '');

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
};

Modal.setAppElement('#root');

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loggedIn: false,
			showAuth: false,
			user: {},
			addr: '',
			date: moment(),
			allRecords: null,
			records: [],
			imageURL: null,
			location: {
				category: 'Commercial',
				subcategory: '',
			},
			reportComps: [],
		};
	}

	async componentDidMount() {
		const currentSession = await Auth.currentSession();
		if (currentSession) {
			const user = await Auth.currentAuthenticatedUser();
			this.setState({ user: user.attributes, loggedIn: true });
		}
	}

	handleAuthStateChange = async (authState) => {
		if (authState === 'signedIn') {
			this.setState({
				loggedIn: true,
				showAuth: false,
			});
		}
	};

	signOut = async () => {
		try {
			await Auth.signOut();
			await this.setState({
				user: null,
				loggedIn: false,
			});
		} catch (err) {
			console.log('error in signOut:', err);
		}
	};

	signIn = async () => {
		this.setState({ showAuth: true });
	};

	closeAuth = async () => {
		this.setState({ showAuth: false });
	};

	// getGeoData = async (addr) => {
	//     const data = await Geocode.fromAddress(addr);
	//     const { lat, lng } = data.results[0].geometry.location;
	//     return { latitude: lat, longitude: lng };
	// }

	getRecords = async () => {
		try {
			const response = await API.get('howerAPI', `/records`);
			const records = response.data;
			const allRecords = records.filter((record) => !record.deleted);

			// // update lat long for any records not yet updated
			// const allRecordsWithLatLongs = allRecords.map(async (r, i) => {
			// 	let updatedRecord = r;
			// 	const vals = (((r || {}).PropertyInfo || {}).values || {});
			// 	if (typeof vals.latitude === 'undefined' || typeof vals.longitude === 'undefined') {
			// 		if (typeof vals.address !== 'undefined' && typeof vals.city !== 'undefined') {
			// 			const addr = `${vals.address},${vals.city},CA`;
			// 			let geoDataFound = false;
			// 			try {
			// 				const geoData = await this.getGeoData(addr);
			// 			 	if (geoData.latitude && geoData.longitude) {
			// 				 	geoDataFound = true;
			// 					r.PropertyInfo.values = {
			// 						...r.PropertyInfo.values,
			// 						...geoData
			// 					}
			// 					updatedRecord = r;
			// 				}
			// 			} catch (err) {
			// 				console.log(`There was an error getting lat long:`, err);
			// 			}

			// 			// if we have lat/lng update the database with lat/lng
			// 			// so we don't need to call geocoder again for this record
			// 			if (geoDataFound) {
			// 				try {
			// 					const recRes = await API.post('howerAPI', '/records', {
			// 						body: r,
			// 					});
			// 				} catch (err) { console.log(`An error occurred during record update:`, err) }
			// 			}
			// 		}
			// 	}
			// 	return updatedRecord
			// })

			await this.setState({ allRecords });
		} catch (error) {}
		return true;
	};

	addToReport = (comp) => {
		const reportComps = [...this.state.reportComps];
		reportComps.push(comp);
		this.setState({ reportComps, imageURL: '' });
	};

	removeFromReport = async (comp, removeAll = false) => {
		if (removeAll) {
			await this.setState({ reportComps: [], imageURL: '' });
		} else {
			let reportComps = [...this.state.reportComps];
			const compIndex = reportComps.findIndex((c) => {
				let addA = (c.PropertyInfo.values || {}).address || '';
				let addB = (comp.PropertyInfo.values || {}).address || '';
				return addA === addB;
			});
			reportComps.splice(compIndex, 1);
			await this.setState({ reportComps, imageURL: '' });
		}
	};

	updateImageURL = (imageURL) => {
		this.setState({ imageURL });
	};

	updateRecord = (record) => {
		let allRecords = this.state.allRecords || [];
		this.setState({
			allRecords: allRecords.map((existingRecord) =>
				existingRecord.id === record.id ? record : existingRecord
			),
		});
	};

	updateLocation = (key, val) => {
		const location = { ...this.state.location };
		location[key] = val;
		this.setState({ location });
	};

	render() {
		const allProps = {
			user: this.state.user,
			date: this.state.date,
			addr: this.state.addr,
			getRecords: this.getRecords,
			addToReport: this.addToReport,
			removeFromReport: this.removeFromReport,
			addRecord: this.addRecord,
			allRecords: this.state.allRecords,
			imageURL: this.state.imageURL,
			updateImageURL: this.updateImageURL,
			reportComps: this.state.reportComps,
			location: this.state.location,
			updateLocation: this.updateLocation,
			getDbKey,
		};

		return (
			<RecordsContext.Provider value={this.updateRecord}>
				<UserContext.Provider value={this.state.user}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Router>
							<Page
								loggedIn={this.state.loggedIn}
								signIn={this.signIn}
								signOut={this.signOut}
							>
								<Switch>
									<Route exact path={`/`}>
										<Home {...allProps} />
									</Route>
									<Route exact path={`/addData`}>
										<AddData {...allProps} />
									</Route>
									<Route exact path={`/viewData`}>
										<ViewData
											{...allProps}
											allRecords={this.state.allRecords}
										/>
									</Route>
								</Switch>
							</Page>
							{this.state.showAuth && <div className="AuthModalOverlay" />}
							<Modal
								isOpen={this.state.showAuth}
								onRequestClose={this.closeAuth}
								style={customStyles}
								className="AuthModal"
								overlayClassName="ParentAuthOverlay"
								shouldCloseOnOverlayClick={true}
							>
								<Authenticator
									amplifyConfig={config}
									onStateChange={this.handleAuthStateChange}
									hide={[
										CustomSignIn,
										ConfirmSignIn,
										VerifyContact,
										CustomForgotPassword,
										RequireNewPassword,
									]}
									hideDefault={true}
								>
									<CustomSignIn closeAuth={this.closeAuth} />
									<ConfirmSignIn />
									<VerifyContact />
									<CustomForgotPassword />
									<RequireNewPassword />
								</Authenticator>
							</Modal>
						</Router>
					</MuiPickersUtilsProvider>
				</UserContext.Provider>
			</RecordsContext.Provider>
		);
	}
}

export default App;
