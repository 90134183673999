import Box from '@material-ui/core/Box';
import FormModal from './FormModal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { recordTypes } from '@hower/shared';

const Subcategory = ({ category, subcategory }) => {
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<ListItem button key={subcategory} onClick={() => setOpen(true)}>
				{recordTypes[category].subcategories[subcategory].label}
			</ListItem>
			<FormModal
				category={category}
				handleClose={() => setOpen(false)}
				open={open}
				subcategory={subcategory}
			/>
		</React.Fragment>
	);
};

const Category = ({ category }) => (
	<React.Fragment>
		<ListSubheader>{recordTypes[category].label}</ListSubheader>
		{Object.keys(recordTypes[category].subcategories).map((subcategory) => (
			<Subcategory
				category={category}
				key={subcategory}
				subcategory={subcategory}
			/>
		))}
	</React.Fragment>
);

const AddData = () => {
	return (
		<div>
			<Box display="flex" width={900} ml="auto" mr="auto" mt="20px">
				{Object.keys(recordTypes).map((category) => (
					<Box width={400} mt={2} mx={2} key={category}>
						<Paper>
							<List disablePadding>
								<Category category={category} />
							</List>
						</Paper>
					</Box>
				))}
			</Box>
		</div>
	);
};

export default AddData;
