import React, { useState, useCallback, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Context from '../Context';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import uuid from 'uuid4';

const useStyles = makeStyles((theme) => ({
	dropzone: {
		backgroundColor: theme.palette.grey['100'],
		borderColor: (active) => theme.palette.grey[active ? '500' : '300'],
		borderStyle: 'dashed',
		borderWidth: '2px',
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		textAlign: 'center',
	},
	cropMsg: {
		fontSize: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '8px 0',
		fontWeight: '400',
		color: '#565656',
	},
}));

const MainImageUploader = (props) => {
	const getCanvasBlob = (canvas) => {
		return new Promise(function (resolve, reject) {
			canvas.toBlob(function (blob) {
				resolve(blob);
			});
		});
	};

	const { actions } = React.useContext(Context);
	// ---->>>
	const generateDownload = async (canvas, crop) => {
		const currentImg = imgRef.current;
		console.log(`currentImg:`, currentImg);

		if (!crop || !canvas) {
			return;
		}

		try {
			const file = await getCanvasBlob(canvas);
			console.log(`file:`, file);
			const imageUpload = actions.addEntry({
				file,
				isPrimary: true,
				name: currentImg.name.replace(/\.[^/.]+$/, ''),
				s3Key: `${uuid()}.${currentImg.name.split('.').pop()}`,
			});
			console.log(`imageUpload:`, imageUpload);
		} catch (error) {
			console.log(`error in getCanvasBlob or addEntry:`, error);
		}
	};
	// ---->>>

	// ---->>>
	const [srcFile, setSrcFile] = useState();
	const imgRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const [completedCrop, setCompletedCrop] = useState(null);
	// ---->>>

	// ---->>>
	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = completedCrop;

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
	}, [completedCrop]);
	// ---->>>

	const [crop, setCrop] = useState({ aspect: 1 });
	const [h, setH] = useState(null);
	const [w, setW] = useState(null);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [imageHeight, setImageHeight] = useState(null);
	const [imageWidth, setImageWidth] = useState(null);
	const [imageObject, setimageObject] = useState(null);

	const onImageLoaded = (img) => {
		imgRef.current = img;
		const hGW = img.height >= img.width;
		const height = hGW ? img.width : img.height;
		const width = !hGW ? img.height : img.width;
		setImageHeight(img.height);
		setImageWidth(img.width);
		setH(height);
		setW(width);
		return false;
	};

	const cancelCrop = () => {
		setHasLoaded(false);
		setCrop({ aspect: 1 });
		setimageObject(null);
	};

	const onCrop = (newCrop) => {
		let nc = {
			...newCrop,
			height: h,
			width: w,
		};
		if (imageHeight > imageWidth) {
			const y = newCrop.y + h > imageHeight ? imageHeight - h : newCrop.y;
			setCrop({ ...nc, x: 0, y });
		} else {
			const x = newCrop.x + w > imageWidth ? imageWidth - w : newCrop.x;
			setCrop({ ...nc, y: 0, x });
		}
	};
	const accept = 'image/*';
	const onDrop = React.useCallback(
		(acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				setimageObject(file);

				// ---->>>
				const reader = new FileReader();
				reader.addEventListener('load', () => setSrcFile(reader.result));
				reader.readAsDataURL(file);
				// ---->>>
			});
		},
		[actions]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept,
		multiple: false,
		onDrop,
	});
	const classes = useStyles(isDragActive);

	return (
		<div>
			{imageObject && (
				<div>
					{!hasLoaded && (
						<h4 className={classes.cropMsg}>
							To crop, click the image and drag the square.
						</h4>
					)}
					<ReactCrop
						src={srcFile}
						crop={crop}
						onComplete={(c) => setCompletedCrop(c)}
						onDragStart={(event) => setHasLoaded(true)}
						onImageLoaded={onImageLoaded}
						onChange={onCrop}
					/>

					<canvas
						ref={previewCanvasRef}
						style={{
							display: 'none',
							width: Math.round(completedCrop?.width ?? 0),
							height: Math.round(completedCrop?.height ?? 0),
						}}
					/>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: '5px',
						}}
					>
						<Button
							disabled={!completedCrop?.width || !completedCrop?.height}
							onClick={() =>
								generateDownload(previewCanvasRef.current, completedCrop)
							}
						>
							Crop & Save
						</Button>
						<Button onClick={cancelCrop}>Cancel</Button>
					</div>
				</div>
			)}
			{!imageObject && (
				<Box className={classes.dropzone} {...getRootProps()}>
					<input {...getInputProps()} />
					<Box color="text.secondary" py={2}>
						Drag 'n' drop some files here, or click to select files
					</Box>
				</Box>
			)}
		</div>
	);
};

const Uploader = () => {
	const { actions, multiple } = React.useContext(Context);
	const onDrop = React.useCallback(
		(acceptedFiles) => {
			acceptedFiles.forEach((file) =>
				actions.addEntry({
					file,
					isPrimary: false,
					name: file.name.replace(/\.[^/.]+$/, ''),
					s3Key: `${uuid()}.${file.name.split('.').pop()}`,
				})
			);
		},
		[actions]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: 'image/*, application/pdf',
		multiple,
		onDrop,
	});
	const classes = useStyles(isDragActive);

	return (
		<Box className={classes.dropzone} {...getRootProps()}>
			<input {...getInputProps()} />
			<Box color="text.secondary" py={2}>
				Drag 'n' drop some files here, or click to select files
			</Box>
		</Box>
	);
};

const Dropzone = () => {
	const { key: type = '' } = React.useContext(Context);
	const isMain = type === 'MainImage';
	return <div>{isMain ? <MainImageUploader /> : <Uploader />}</div>;
};

export default Dropzone;
