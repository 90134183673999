import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import React from 'react';
import './page.css';

export default function Page({ children, loggedIn, signIn, signOut }) {
	return (
		<div className="Page">
			<nav className="Page-nav">
				<a className="Page-logo" href="/">
					<img alt="Simon Hower Logo" src="/hower-logo.svg" />
					<div className="Page-logoText">
						Simon Hower <span>Reports</span>
					</div>
				</a>
				<ul>
					{loggedIn && (
						<li>
							<Link to="/addData">Add Data</Link>
						</li>
					)}
					{loggedIn && (
						<li>
							<Link to="/viewData">View Data</Link>
						</li>
					)}
					<li>
						{loggedIn ? (
							<button onClick={() => signOut()} type="button">
								Sign Out
							</button>
						) : (
							<button onClick={() => signIn()} type="button">
								Sign In
							</button>
						)}
					</li>
				</ul>
			</nav>
			{loggedIn ? (
				children
			) : (
				<Alert severity="info">Sign in to continue.</Alert>
			)}
		</div>
	);
}
