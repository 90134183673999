import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import TextInput from './TextInput';

const SquareFootInput = (props) => (
	<TextInput
		{...props}
		type="number"
		InputProps={{
			...props.InputProps,
			endAdornment: <InputAdornment position="end">sf</InputAdornment>,
		}}
	/>
);

export default SquareFootInput;
