import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ label, setValue, value, ...props }) => (
	<TextField
		fullWidth
		label={label}
		margin="dense"
		onChange={(event) => setValue(event.target.value)}
		size="small"
		value={typeof value === 'undefined' ? '' : value}
		variant="outlined"
		{...props}
	/>
);

export default TextInput;
